//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        counter: {
            type: [Number, String, Boolean],
            default: false
        },

        disabled: {
            type: Boolean,
            default: false
        },

        hint: {
            type: String,
            default: ""
        },

        id: {
            type: String,
            default: ""
        },

        label: {
            type: String,
            default: ""
        },

        placeholder: {
            type: String,
            default: ""
        },

        prefix: {
            type: String,
            default: ""
        },

        suffix: {
            type: String,
            default: ""
        },

        rules: {
            type: Array,
            default() {
                return [];
            }
        },

        value: {
            type: [String, Number],
            default: ""
        },

        name: {
            type: String,
            default: ""
        },

        autogrow: {
            type: Boolean,
            default: false
        },

        rows: {
            type: [Number, String, Array]
        },

        showAsterisk: {
            type: Boolean,
            deafult: false
        }
    },

    data() {
        return {
            v: "",
            local_id: "",
            error_state: false,
            focused: false,
            textarea_styles: {
                "height": "20px",
                "overflow-y": "auto"
            }
        };
    },

    computed: {
        hasLabelSlot() {
            return !!this.$slots.label;
        },
        hasHintSlot() {
            return !!this.$slots.hint;
        },
        hasPrependOuterSlot() {
            return !!this.$slots["prepend-outer"];
        },
        hasAppendOuterSlot() {
            return !!this.$slots["append-outer"];
        },
        hasPrependSlot() {
            return !!this.$slots.prepend;
        },
        hasAppendSlot() {
            return !!this.$slots.append;
        },
        maxRows() {
            if (typeof this.rows === "number" || typeof this.rows === "string") {
                return parseInt(this.rows);
            } else if (Array.isArray(this.rows)) {
                if (this.rows.length > 1) return parseInt(this.rows[1]);
                return parseInt(this.rows[0]);
            } else {
                return 1;
            }
        },
        minRows() {
            if (Array.isArray(this.rows) && this.rows.length > 0) {
                return parseInt(this.rows[0]);
            } else {
                return 1;
            }
        }
    },

    watch: {
        id() {
            this.handleId();
        },
        value() {
            this.handleValue();
        },
        v() {
            this.$emit("input", this.v);
        },
        autogrow() {
            if (this.autogrow === true) this.resizeTextarea();
        }
    },

    methods: {
        validate() {
            return this.$refs["wk-input"].validate();
        },

        resetValidation() {
            return this.$refs["wk-input"].resetValidation();
        },

        handleId() {
            if (this.id) {
                this.local_id = this.id;
            } else {
                this.local_id = "input_" + (Date.now() + Math.random() * 100000).toString(16);
            }
        },

        handleValue(withoutValidation = false) {
            if (this.value != undefined) {
                this.v = this.value;
                if (!withoutValidation) {
                    this.$nextTick(this.validate);
                }
            }
        },

        resizeTextarea() {
            if (!this.autogrow) return;
            this.textarea_styles.height = "auto";
            this.$nextTick(() => {
                const realHeight = this.$refs["input"].scrollHeight;
                // console.log(realHeight)

                if (realHeight > 20 * this.maxRows) {
                    this.textarea_styles.height = 20 * this.maxRows + "px";
                    this.textarea_styles["overflow-y"] = "auto";
                } else if (realHeight < 20 * this.minRows) {
                    this.textarea_styles.height = 20 * this.minRows + "px";
                    this.textarea_styles["overflow-y"] = "hidden";
                } else {
                    this.textarea_styles.height = realHeight + "px";
                    this.textarea_styles["overflow-y"] = "hidden";
                }
            });
        }
    },

    created() {
        this.handleId();
    },

    mounted() {
        this.handleValue(true);
        if (this.autogrow) this.resizeTextarea();
    }
};
