import { saveInLocalStorage } from "../../helpers/local-storage";

export default {
    namespaced: true,

    state: () => ({
        debtor_personal_data: {
            name: "",
            email: "",
            pesel: "",
            has_nip: false,
            nip: "",
            phone: "",
            birth_date: "",
            billing_address: {
                country: "",
                city: "",
                street: "",
                house_no: "",
                apartment_no: "",
                zip_code: ""
            },
            address: {
                country: "",
                city: "",
                street: "",
                house_no: "",
                apartment_no: "",
                zip_code: ""
            },
            billing_address_same_as_address: true
        },
        debtor_personal_data_validations: {
            name: false,
            email: false,
            pesel: false,
            nip: false,
            phone: false,
            birth_date: false,
            billing_address: {
                country: false,
                city: false,
                street: false,
                house_no: false,
                apartment_no: true,
                zip_code: false
            },
            address: {
                country: false,
                city: false,
                street: false,
                house_no: false,
                apartment_no: true,
                zip_code: false
            }
        },

        survey_validations: {}
    }),

    getters: {},

    mutations: {
        setDebtorDataValidation(state, payload) {
            state.debtor_personal_data_validations = payload;
        },

        setDebtorData(state, payload) {
            state.debtor_personal_data = payload;
        },

        setSurveyValidation(state, payload) {
            if (payload.key !== undefined && payload.value !== undefined) {
                state.survey_validations = {
                    ...state.survey_validations,
                    [payload.key]: payload.value
                };
            } else {
                state.survey_validations = payload;
            }

            saveInLocalStorage("survey_validations", JSON.stringify(state.survey_validations));
        }
    }
};
