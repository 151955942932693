export default {
    namespaced: true,

    state: () => ({
        QUESTIONS_AND_ANSWERS: [
            {
                question: "Czy muszę posiadać majątek?",
                answer: "Przepisy prawa upadłościowego, mające zastosowanie do upadłości osób fizycznych nieprowadzących działalności gospodarczej, nie wymagają od upadłego, aby w chwili składania wniosku posiadał majątek.",
                onboarding_question: true
            },
            {
                question: "Czy mogę wygenerować raport BIK, jeśli nie posiadam konta w Banku?",
                answer: `Z reguły, aby wygenerować raport BIK musimy zapłacić 1 zł opłaty weryfikacyjnej, jednakże istnieje również drugie rozwiązanie → <a href="/static/files/bik-bez-konta.pdf" target="_blank" class="text-secondary text-weight-bold" download="Instrukcja-generowania-raportu-BIK-bez-konta.pdf">instrukcja generowania raportu BIK bez konta bankowego</a>`,
                onboarding_question: true
            },
            {
                question: "Czym jest EPU?",
                answer: "EPU to skrót od Elektroniczne Postępowanie Upominawcze, po założeniu profilu mamy dostęp do swoich spraw, w których został wydany nakaz zapłaty.",
                onboarding_question: false
            },
            {
                question: "Czym jest KRZ?",
                answer: "KRZ jest rejestrem jawnym, prowadzonym przez Ministra Sprawiedliwości w systemie elektronicznym. Zgodnie z uzasadnieniem projektu Ustawy o KRZ, głównymi celami utworzenia rejestru są m.in.  zapewnienie wierzycielom bieżącego dostępu do akt postępowań restrukturyzacyjnych oraz upadłościowych za pośrednictwem Internetu, zwiększenie bezpieczeństwa obrotu gospodarczego i stopnia zaspokajania wierzycieli w postępowaniach upadłościowych oraz przyspieszenie i usprawnienie postępowań restrukturyzacyjnych i upadłościowych oraz zwiększenie ich efektywności.",
                onboarding_question: true
            },
            {
                question: "Czy muszę pracować, jeśli chcę ogłosić upadłość?",
                answer: "Nie, jednak sąd może wyznaczyć termin na znalezienie pracy, jeśli oczywiście warunki fizyczne upadłego oraz rynek na to pozwala.",
                onboarding_question: true
            },
            {
                question: "Czy Syndyk zajmie mi konto",
                answer: "Po ogłoszeniu upadłości konsumenckiej bank blokuje możliwość korzystania z konta osobistego przez upadłego - pieniądze zgromadzone na rachunkach od teraz wchodzą też w skład masy upadłościowej, a wyłączne prawo do zarządzania nimi przechodzi na syndyka, jednak Syndyk wyłączy część środków niezbędnych do funkcjonowania.",
                onboarding_question: false
            },
            {
                question: "Jak długo trwa plan spłaty?",
                answer: "W sytuacji, gdy dłużnik istotnie przyczyni się do powstania zadłużenia plan spłaty zostanie ustalony od 4-7 lat, jednak jeśli niewypłacalność nie powstała z winy dłużnika Sąd ustali plan spłaty od 1-3 lat.",
                onboarding_question: true
            },
            {
                question: "Co jest moim majątkiem?",
                answer: "Do majątku dłużnika wchodzą przede wszystkim środki zgromadzone na rachunku bankowym, pieniądze w gotówce, wszelkie nieruchomości oraz grunty, ruchomości z wyłączeniem art. gospodarstwa domowego, do których należą: pralka, lodówka, kuchenka (oczywiście, jeśli ich wartość nie jest wyjątkowo wysoka).",
                onboarding_question: false
            },
            {
                question: "Czy komornik może zająć mi świadczenia np. 500+?",
                answer: "Wszystkie świadczenia socjalne są zwolnione z egzekucji komorniczych. Zgodnie z polskim prawem 500 plus jest zatem wolne od zajęcia komorniczego, co oznacza, że komornik nie ma takich uprawnień, nawet jeśli mamy długi.",
                onboarding_question: false
            }
        ]
    }),

    getters: {
        getAllQuestions(state) {
            return state.QUESTIONS_AND_ANSWERS;
        },

        getOnboardingQuestions(state) {
            return state.QUESTIONS_AND_ANSWERS.filter(q => q.onboarding_question);
        }
    },

    mutations: {}
};
