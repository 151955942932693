var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.handleClickOutside),expression:"handleClickOutside"}],ref:"root_el",staticClass:"wk-select-element"},[_c('div',{staticClass:"wk-select-element__inner",on:{"click":function($event){$event.stopPropagation();return _vm.toggleMenu.apply(null, arguments)}}},[_c('div',{staticClass:"wk-select-element__selection"},[(_vm.isEmpty || !_vm.selectedItem)?_c('div',{staticClass:"wk-select-element__placeholder"},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]):[(_vm.hasSelectionSlot)?_vm._t("selection",null,{"item":_vm.selectedItem}):[_vm._v(" "+_vm._s(_vm.selectedItem.text)+" ")]]],2),_c('div',{staticClass:"wk-select-element__ticon"},[_c('PpIcon',{attrs:{"size":"16","name":_vm.menu_visible ? 'chevron-up' : 'chevron-down'}})],1)]),_c('portal',[_c('div',{ref:"menu",staticClass:"wk-select-element-dropdown",class:{
                'wk-select-element-dropdown--invalid': _vm.error,
                'wk-select-element-dropdown--mobile': _vm.isMobile,
                'wk-select-element-dropdown--mobile-visible': _vm.isMobile && _vm.menu_visible
            },style:({
                width: _vm.parsedDropdownWidth
            }),attrs:{"data-hidden":!_vm.menu_visible},on:{"click":function($event){$event.stopPropagation();}}},[(_vm.placeholder != '' && _vm.isMobile)?_c('h6',{staticClass:"wk-select-element-dropdown__heading"},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]):_vm._e(),(_vm.hasPrependItemSlot)?_vm._t("prepend-item"):_vm._e(),_c('div',{staticClass:"wk-select-element-dropdown__wrapper",class:{
                    'wk-select-element-dropdown__wrapper--mobile': _vm.isMobile
                }},[_c('div',{staticClass:"wk-select-element-dropdown__options"},[_vm._l((_vm.items),function(item){return _c('div',{key:item.value,staticClass:"wk-select-element-dropdown__item",class:{
                            'wk-select-element-dropdown__item--selected': _vm.isSelected(
                                item.value
                            ),
                            'wk-select-element-dropdown__item--disabled': item.disabled === true
                        },attrs:{"tabindex":_vm.menu_visible ? 0 : -1},on:{"click":function (_) {
                                if (item.disabled !== true) { _vm.selectItem(item.value); }
                            },"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return (function (_) {
                                if (item.disabled !== true) { _vm.selectItem(item.value); }
                            }).apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();return (function (_) {
                                if (item.disabled !== true) { _vm.selectItem(item.value); }
                            }).apply(null, arguments)}]}},[(_vm.hasItemSlot)?_vm._t("item",null,{"item":item,"isSelected":_vm.isSelected(item.value)}):_c('div',[(_vm.multiple)?_c('WkCheckbox',{attrs:{"value":_vm.isSelected(item.value),"label":item.text,"tabindex":"-1"},on:{"input":function (_) {
                                        if (item.disabled !== true) { _vm.selectItem(item.value); }
                                    }}}):_c('div',{staticClass:"wk-select-element-dropdown__itemtext"},[_vm._v(" "+_vm._s(item.text)+" ")])],1)],2)}),(_vm.items.length == 0)?_c('div',{staticClass:"wk-select-element-dropdown__nodata"},[_vm._v(" "+_vm._s(_vm.emptyListMsg)+" ")]):_vm._e()],2)]),(_vm.isMobile && (_vm.multiple || _vm.items.length == 0))?_c('div',{staticClass:"wk-select-element-dropdown__footer"},[_c('WkButton',{attrs:{"color":"success","block":""},on:{"click":_vm.closeMenu}},[_vm._v(" "+_vm._s(_vm.items.length == 0 ? "Zamknij" : "Zatwierdź")+" ")])],1):_vm._e()],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }