var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pp-spinner",class:{
        'pp-spinner--absolute': _vm.absolute
    },style:({
        'background-color': _vm.computedBackground
    })},[_c('div',{staticClass:"pp-spinner__inner",style:({
            'width': _vm.computedSize + 'px',
            'height': _vm.computedSize + 'px'
        })},[_c('svg',{staticClass:"pp-spinner__spinner",attrs:{"viewBox":"0 0 50 50"}},[_c('circle',{staticClass:"path",attrs:{"cx":"25","cy":"25","r":"21","fill":"none","stroke":_vm.computedColor,"stroke-width":"4"}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }