//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    
    props: {
        
        type: {
            type: String,
            default: 'info'
        },

        title: {
            type: String
        },

        message: {
            type: String
        }

    },

    data(){
        return {
            is_mobile: false
        }
    },

    computed: {
        hasTitleSlot(){
            return !!this.$slots.title;
        },
        hasMessageSlot(){
            return !!this.$slots.message;
        },

        iconName(){
            if(this.type == 'info') return 'info-circle';
            else if(this.type == 'success') return 'check-circle';
            else if(this.type == 'error') return 'close-circle';
        }
    },

    methods: {
        checkMobile(){
            if(window.innerWidth <= 650) this.is_mobile = true;
            else this.is_mobile = false;
        }
    },

    mounted(){
        this.checkMobile();
        window.addEventListener('resize', this.checkMobile, {passive: true});
    },

    beforeDestroy(){
        window.removeEventListener('resize', this.checkMobile, {passive: true});
    }
}
