/*******************************************************************************
 *
 * PLIK ZAWIERAJĄCY MESSAGES MAJĄCE ZASTĘPOWAĆ ZWROTKI Z API PO polu error_code
 * ORAZ HELPERY DO ICH POBIERANIA
 *
 *******************************************************************************/
const MESSAGES = {
    auth: {
        e201: "Nieprawidłowy login lub hasło",
        e202: "Dokończ rejestrację, by móc się zalogować",
        e203: "Konto nie zostało aktywowane",
        e204: "Konto zostało zablokowane z powodu wielu nieudanych prób logowania",
        e205: "Konto zostało zawieszone przez Administrację serwisu",

        e301: "Możliwość ponownego wysłania wiadomości będzie znowu dostępna za parę minut",

        e401: "Nieprawidłowy lub nieważny token",

        e501: "Możliwość ponownego wysłania wiadomości będzie znowu dostępna za parę minut",

        e601: "Nieprawidłowy lub nieważny token",

        e901: "To konto nie może zostać aktywowane",
        e902: "Nieprawidłowy lub nieważny token",

        e1001: "To konto nie może być aktywowane",
        e1002: "Możliwość ponownego wysłania wiadomości będzie znowu dostępna za parę minut"
    },

    users: {
        e201: "Istnieje już w systemie konto powiązane z podanym adresem e-mail",

        e502: "Rozdzielczość przesłanego obrazu jest zbyt niska",
        e503: "Rozdzielczość przesłanego obrazu jest zbyt wysoka",

        e601: "Podano nieprawidłowe aktualne hasło",

        e1202: "Nie możesz usunąć swojego konta",
        e1203: "To konto nie może zostać usunięte"
    },

    applications: {
        e500: "Podano nieprawidłowy adres e-mail",
        e501: "Wniosek znajduje się obecnie na statusie, który uniemożliwia edycję tych danych",
        e502: "Na obecnym statusie wniosku musisz dokonać pełnej edycji danych, aby móc wprowadzić jakiekolwiek zmiany",

        e701: "Upewnij się, że że żadna z odpowiedzi na liście nie jest pusta",
        e702: "Wniosek znajduje się obecnie na statusie, który uniemożliwia edycję tych danych",

        e901: "Wniosek znajduje się obecnie na statusie, który uniemożliwia edycję tych danych",
        e902: "Aby przejść dalej, najpierw uzupełnij wszystkie wymagane dane",

        e1001: "Wniosek znajduje się obecnie na statusie, który uniemożliwia edycję tych danych",
        e1002: "Aby przejśc dalej, najpierw uzupełnij poprawnie dane ankiety",

        e1101: "Wniosek znajduje się obecnie na statusie, który uniemożliwia edycję tych danych"
    },

    debts: {
        e200: "Przesłano błędne lub niekompletne dane Wierzyciela",
        e201: "Wniosek znajduje się na statusie, który uniemożliwia zarządzanie Wierzycielami",
        e202: "Nie możesz dodać więcej niż 512 Wierzycieli do swojego Wniosku",

        e400: "Przesłano błędne lub niekompletne dane Wierzyciela",

        e602: "Nie można usunąć wierzyciela, do którego dodano jakiekolwiek dokumenty"
    },

    property_components: {
        e200: "Przesłano błędne lub niekompletne dane Składnika Majątku",
        e201: "Wniosek znajduje się na statusie, który uniemożliwia zarządzanie Składnikami Majątku",
        e202: "Nie możesz dodać więcej niż 512 Składników Majątku do swojego Wniosku",

        e400: "Przesłano błędne lub niekompletne dane Składnika Majątku"
    },

    recurring_expenses: {
        e200: "Przesłano błędne lub niekompletne dane Wydatku Cyklicznego",
        e201: "Wniosek znajduje się na statusie, który uniemożliwia zarządzanie Wydatkami Cyklicznymi",
        e202: "Nie możesz dodać więcej niż 512 Wydatków Cyklicznych do swojego Wniosku",

        e400: "Przesłano błędne lub niekompletne dane Wydatku Cyklicznego"
    },

    documents: {
        e403: "Ten Dokument został zablokowany przez Prawnika i nie może być edytowany",
        e502: "Ten typ Dokumentu nie może zostać usunięty",
        e503: "Ten Dokument został zablokowany przez Prawnika i nie może być już usunięty"
    },

    files: {
        e206: "Dokument, do którego próbujesz wgrać pliki jest zablokowany, przez co nie można dodać do niego już więcej plików",
        e803: "Dokument, do którego próbujesz wgrać pliki jest zablokowany, przez co nie można usunąć żadnego z plików w nim zawartych"
    }
};

function getNestedObjProperty(obj, property) {
    let work_obj = obj;
    const splitted_property = property.split(".");

    for (let i = 0; i < splitted_property.length; i++) {
        if (typeof work_obj != "object" || work_obj[splitted_property[i]] == undefined) {
            return null;
        }

        work_obj = work_obj[splitted_property[i]];
    }

    return work_obj;
}

export function messageExists(path) {
    if (!path || path == "") return false;
    return getNestedObjProperty(MESSAGES, path) !== null;
}

export function getMessage(path) {
    if (!path || path == "") return "";
    const t = getNestedObjProperty(MESSAGES, path);
    return t === null ? path : t;
}
