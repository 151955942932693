//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { createPopper } from "@popperjs/core";
import { EventBus } from "@/helpers/EventBus";

export default {
    props: {
        placeholder: {
            type: String,
            default: ""
        },

        value: {
            type: [String, Number, Boolean, Array],
            default: ""
        },

        items: {
            type: Array,
            default() {
                return [];
            }
        },

        multiple: {
            type: Boolean,
            default: false
        },

        emptyListMsg: {
            type: String,
            default: "Options list is empty"
        },

        disabled: {
            type: Boolean,
            default: false
        },

        error: {
            type: Boolean,
            default: false
        },

        popperDistance: {
            type: [String, Number],
            default: 0
        },
        popperSkidding: {
            type: [String, Number],
            default: 0
        },

        dropdownWidth: {
            type: [String, Number]
        }
    },

    data() {
        return {
            local_id: null,
            is_focused: false,
            menu_visible: false,
            popper: null,

            hasItemSlot: false,
            hasSelectionSlot: false,
            hasPrependItemSlot: false,
            hasAppendItemSlot: false,

            cachedSelectedItem: null
        };
    },

    computed: {
        isEmpty() {
            if (Array.isArray(this.value)) return this.value.length === 0;
            return this.value === "";
        },
        selectedItem() {
            if (this.cachedSelectedItem != null) return this.cachedSelectedItem;
            if (!this.multiple) return this.items.find(item => item.value === this.value);
            else
                return {
                    text: this.items
                        .filter(item => this.value.indexOf(item.value) !== -1)
                        .map(item => item.text)
                        .join(", ")
                };
        },

        isMobile() {
            return this.$device.mobile;
        },

        parsedDropdownWidth() {
            if (typeof this.dropdownWidth === "number") return this.dropdownWidth.toString() + "px";
            return this.dropdownWidth;
        }
    },

    watch: {
        value() {
            this.cacheSelectedItem(this.value);
        }
    },

    methods: {
        selectItem(v) {
            if (this.multiple) {
                const CP = JSON.parse(JSON.stringify(this.value));
                const ix = CP.indexOf(v);
                if (ix === -1) {
                    CP.push(v);
                } else {
                    CP.splice(ix, 1);
                }
                this.$emit("input", CP);
                this.$emit("change", CP);
            } else {
                this.closeMenu();
                this.$emit("input", v);
                this.$emit("change", v);
            }
            this.cacheSelectedItem(v);
        },
        isSelected(v) {
            if (this.multiple && Array.isArray(this.value)) {
                return this.value.findIndex(item => item == v) !== -1;
            } else {
                return this.value == v;
            }
        },

        cacheSelectedItem(v) {
            let item = this.items.find(i => i.value == v);
            if (!item) {
                this.cachedSelectedItem = null;
                return;
            }
            item = JSON.parse(JSON.stringify(item));

            if (this.multiple) {
                if (this.cachedSelectedItem == null) this.cachedSelectedItem = [];
                this.cachedSelectedItem.push(item);
            } else {
                this.cachedSelectedItem = item;
            }
        },

        focus() {
            this.is_focused = true;
            this.$emit("focus", true);
        },
        blur() {
            this.is_focused = false;
            this.$emit("blur", true);
        },

        toggleMenu() {
            if (this.menu_visible == false) this.openMenu();
            else this.closeMenu();
        },
        openMenu() {
            if (this.disabled || this.menu_visible == true) return;
            if (!this.isMobile) {
                this.mountPopper();
            }
            this.menu_visible = true;
            EventBus.$emit("wk-select-element:opened", this.local_id);
        },
        closeMenu() {
            if (this.menu_visible !== true) return;
            if (!this.isMobile) {
                this.destroyPopper();
            }
            this.menu_visible = false;
        },

        mountPopper() {
            if (this.popper != null) return;
            this.popper = createPopper(this.$refs["root_el"], this.$refs["menu"], {
                strategy: "absolute",
                placement: "bottom",
                modifiers: [
                    {
                        name: "flip",
                        options: {
                            fallbackPlacements: ["top"]
                        }
                    },
                    {
                        name: "offset",
                        options: {
                            offset: [this.popperSkidding, this.popperDistance]
                        }
                    }
                ]
            });
        },
        destroyPopper() {
            if (this.popper == null) return;
            this.popper.destroy();
            this.popper = null;
        },

        hoso(id) {
            if (id != this.local_id && this.menu_visible === true) this.menu_visible = false;
        },

        checkSlots() {
            this.hasItemSlot = !!this.$scopedSlots.item;
            this.hasSelectionSlot = !!this.$scopedSlots.selection;
            this.hasAppendItemSlot = !!this.$slots["append-item"];
            this.hasPrependItemSlot = !!this.$slots["prepend-item"];
        },

        handleClickOutside(e) {
            if (
                this.$refs.menu.contains(e.target) ||
                e.target.classList.contains("wk-select-element-dropdown")
            ) {
                return false;
            }
            this.closeMenu();
        }
    },

    created() {
        this.local_id = "select_" + (Date.now() + Math.random() * 100000).toString(16);
    },

    mounted() {
        EventBus.$on("wk-select-element:opened", this.hoso);
        this.cacheSelectedItem(this.value);
        this.checkSlots();
    },

    updated() {
        this.checkSlots();
    },

    beforeDestroy() {
        EventBus.$off("wk-select-element:opened", this.hoso);
    }
};
