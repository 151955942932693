var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"wk-tooltip"},[_c('span',{ref:"popper",staticClass:"wk-tooltip-ttip",attrs:{"data-visible":_vm.tooltip_visible}},[_c('span',{staticClass:"wk-tooltip-ttip__content"},[_vm._t("default",function(){return [_vm._v("No tooltip content")]})],2),(_vm.$device.mobile)?_c('button',{staticClass:"wk-tooltip-ttip__close",on:{"click":_vm.hideTooltip}},[_c('PpIcon',{attrs:{"name":"close","size":"12"}})],1):_vm._e(),_c('span',{ref:"arrow",staticClass:"wk-tooltip-ttip__arrow",attrs:{"data-popper-arrow":""}})]),_c('span',{ref:"reference",staticClass:"wk-tooltip__reference",on:{"mouseenter":function (_) {
                if (!_vm.$device.mobile) { _vm.showTooltip(); }
            },"mouseleave":function (_) {
                if (!_vm.$device.mobile) { _vm.hideTooltip(); }
            },"click":function (_) {
                if (_vm.$device.mobile) {
                    if (_vm.tooltip_visible) { _vm.hideTooltip(); }
                    else { _vm.showTooltip(); }
                }
            }}},[_vm._t("reference")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }