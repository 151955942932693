export default {
    namespaced: true,

    state: () => ({
        application: null,
        application_fetching: false,
        application_fetched: false,

        APPLICATION_STATUSES: {
            CREATED: "created",
            PAYMENT_SETUP: "payment_setup",
            PAYMENT_REJECTED: "payment_rejected",
            PAID: "paid",
            PERSONAL_DATA: "personal_data",
            SURVEY: "survey",
            PROPERTY_COMPONENTS: "property_components",
            RECURRING_EXPENSES: "recurring_expenses",
            DEBTS: "debts",
            DOCUMENTS: "documents",
            VERIFICATION_ERROR: "verification_error",
            VERIFIED: "verified",
            GENERATED: "generated",
            SUBMITTED: "submitted",
            SUCCEEDED: "succeeded",
            REJECTED: "rejected",
            CANCELED: "canceled"
        },

        APPLICATION_STATUSES_ORDER: {
            created: 1,
            payment_setup: 2,
            payment_rejected: 3,
            paid: 4,
            personal_data: 5,
            survey: 6,
            property_components: 7,
            recurring_expenses: 8,
            debts: 9,
            documents: 10,
            verification_error: 11,
            verified: 12,
            generated: 13,
            submitted: 14,
            succeeded: 15,
            rejected: 16,
            canceled: 17
        }
    }),

    getters: {
        getApplication(state) {
            return state.application;
        },
        getStatusPriority: state => status => {
            return state.APPLICATION_STATUSES_ORDER[status];
        }
    },

    mutations: {
        setApplication(state, payload) {
            state.application = payload;
        }
    },

    actions: {
        // metoda do pobierania wniosku użytkownika z serwera
        fetchApplication({ commit, state }) {
            return new Promise(async (resolve, reject) => {
                try {
                    state.application_fetching = true;
                    try {
                        const r = await this._vm.$axios.$get("/applications/mine/current", {
                            supress_errors: true
                        });

                        commit("setApplication", r.application);
                        state.application_fetched = true;

                        // listener socketów
                        this._vm.$io.on("Application.updated", payload => {
                            this.dispatch("application/updateViaSockets", payload);
                        });
                    } catch (err) {
                        console.error(err);
                        // jeżeli coś poszło nie tak przy pobieraniu to robimy expired session
                        this.dispatch("addMessage", {
                            type: "error",
                            msg: "Do Twojego konta nie przypisano jeszcze żadnego wniosku. Dostęp do Aplikacji tymczasowo wyłączony."
                        });
                        setTimeout(_ => {
                            this.dispatch("auth/expiredSession");
                        }, 100);
                    }

                    state.application_fetching = false;
                    return resolve(true);
                } catch (err) {
                    state.application_fetching = false;
                    return reject(err);
                }
            });
        },

        // aktualizowanie lokalnej application przez sockety
        updateViaSockets({ commit, getters }, data) {
            const app = getters.getApplication;
            if (!app || app._id != data._id) return;

            commit("setApplication", {
                ...app,
                ...data
            });
        },

        // funkcja, która zwraca obietnicę rozwiązującą się w momencie pobrania Application
        awaitForApplication({ state }) {
            return new Promise(resolve => {
                function checkState() {
                    if (state.application_fetched) return resolve();
                    else {
                        setTimeout(checkState, 500);
                    }
                }
                checkState();
            });
        }
    }
};
