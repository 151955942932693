//
//
//
//
//
//
//

export default {
    
    props: {
        height: {
            type: [Number, String],
            default: 100
        }
    },

    computed: {
        getStyle(){
            return {
                height: (typeof this.height == 'number' ? this.height + 'px' : this.height)
            }
        }
    }

}
