var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('WkInput',{ref:"wk-input",attrs:{"hint":_vm.hint,"id":_vm.local_id,"rules":_vm.rules,"value":_vm.value,"error":_vm.error_state,"input-type":"radio"},on:{"update:error":function($event){_vm.error_state=$event}}},[_c('div',{staticClass:"wk-radio",class:{
            'wk-radio--disabled': _vm.disabled
        }},[_c('button',{staticClass:"wk-radio-button",class:{
                'wk-radio-button--checked': _vm.isChecked,
                'wk-radio-button--invalid': _vm.error_state,
                'wk-radio-button--disabled': _vm.disabled,
                'wk-radio-button--focused': _vm.focus
            },attrs:{"disabled":_vm.disabled,"tabindex":_vm.nonFocusable ? '-1' : '0'},on:{"click":function($event){$event.stopPropagation();return _vm.toggle.apply(null, arguments)}}},[_c('div',{staticClass:"wk-radio-button__fill"}),_c('div',{staticClass:"wk-radio-button__icon"})]),(_vm.hasLabelSlot || _vm.label != '')?_c('div',{staticClass:"wk-radio__label",on:{"click":function($event){$event.stopPropagation();return _vm.toggle.apply(null, arguments)}}},[(_vm.hasLabelSlot)?_vm._t("label"):[_vm._v(" "+_vm._s(_vm.label)+" ")]],2):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }