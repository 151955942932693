//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        counter: {
            type: [Number, String, Boolean],
            default: false
        },

        hint: {
            type: String,
            default: ""
        },

        id: {
            type: String,
            default: ""
        },

        label: {
            type: String,
            default: ""
        },

        rules: {
            type: Array,
            default() {
                return [];
            }
        },

        value: {
            type: [String, Number, Boolean, Array],
            default: ""
        },

        showAsterisk: {
            type: Boolean,
            deafult: false
        },

        error: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            msg: "",
            main_width: 100,
            prepend_outer_width: "auto",

            hasLabelSlot: false,
            hasHintSlot: false,
            hasPrependOuterSlot: false,
            hasAppendOuterSlot: false
        };
    },

    computed: {
        counterValue() {
            if (typeof this.counter == "number" && this.counter > 0)
                return this.counter;
            else if (typeof this.counter == "string") {
                const pn = parseInt(this.counter);
                if (!isNaN(pn)) return pn;
            } else if (this.counter === true) return 25;
            return false;
        }
    },

    methods: {
        validate() {
            let invalid = false;
            let msg = null;
            for (let i = 0; i < this.rules.length; i++) {
                if (typeof this.rules[i] == "function") {
                    const r = this.rules[i](this.value);
                    if (r !== true) {
                        invalid = true;
                        if (typeof r == "string") msg = r;
                        break;
                    }
                } else {
                    if (!this.rules[i]) {
                        invalid = true;
                        if (typeof this.rules[i] == "string")
                            msg = this.rules[i];
                        break;
                    }
                }
            }

            this.$emit("update:error", invalid);
            if (msg != null) this.msg = msg;

            return !invalid;
        },

        resetValidation() {
            this.error = false;
        },

        calcMainWidth() {
            this.main_width = this.$refs.main.offsetWidth;
            this.prepend_outer_width = this.hasPrependOuterSlot
                ? this.$refs["prepend-outer"].offsetWidth
                : "auto";
        },

        checkSlots() {
            this.hasLabelSlot = !!this.$slots.label;
            this.hasHintSlot = !!this.$slots.hint;
            this.hasPrependOuterSlot = !!this.$slots["prepend-outer"];
            this.hasAppendOuterSlot = !!this.$slots["append-outer"];
        }
    },

    mounted() {
        this.calcMainWidth();
        this.checkSlots();
        window.addEventListener("resize", this.calcMainWidth, {
            passive: true
        });
    },

    updated() {
        this.checkSlots();
        this.calcMainWidth();
    },

    beforeDestroy() {
        window.removeEventListener("resize", this.calcMainWidth, {
            passive: true
        });
    }
};
