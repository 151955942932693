export default {
    namespaced: true,

    state: () => ({
        USERS_DATA: [],
        CURRENTLY_FETCHING: []
    }),

    getters: {
        userIsFetched: state => id => {
            return state.USERS_DATA.findIndex(item => item._id == id) !== -1;
        },
        getUser: state => id => {
            return state.USERS_DATA.find(item => item._id == id);
        }
    },

    mutations: {
        addUser(state, user) {
            if (!user) return false;
            if (state.USERS_DATA.findIndex(item => item._id == user._id) === -1) {
                state.USERS_DATA.push(user);
            }
            return true;
        },
        removeUser(state, user_id) {
            const ix = state.USERS_DATA.findIndex(item => item._id == user_id);
            if (ix !== -1) state.USERS_DATA.splice(ix, 1);
        },
        setUserActivityStatus(state, data) {
            if (!data.user_id) return;
            const ix = state.USERS_DATA.findIndex(u => u._id == data.user_id);
            if (ix !== -1) {
                const D = JSON.parse(JSON.stringify(state.USERS_DATA[ix]));
                D.activity_status = data.status;
                if (data.last_seen !== undefined) {
                    D.last_seen = data.last_seen;
                } else {
                    delete D.last_seen;
                }
                state.USERS_DATA.splice(ix, 1, D);
            }
        }
    },

    actions: {
        fetchUser({ commit, state, getters }, user_id) {
            return new Promise(async (resolve, reject) => {
                try {
                    if (getters.userIsFetched(user_id)) {
                        return resolve(getters.getUser(user_id));
                    } else if (state.CURRENTLY_FETCHING.indexOf(user_id) !== -1) {
                        function checkIfFetched() {
                            if (state.CURRENTLY_FETCHING.indexOf(user_id) !== -1) {
                                setTimeout(checkIfFetched, 500);
                            } else {
                                return resolve(getters.getUser(user_id));
                            }
                        }
                        checkIfFetched();
                    } else {
                        state.CURRENTLY_FETCHING.push(user_id);

                        const r = await this._vm.$axios.$get(`/users/${user_id}`, {
                            supress_errors: true
                        });
                        commit("addUser", r.user);

                        const ix = state.CURRENTLY_FETCHING.indexOf(user_id);
                        if (ix !== -1) state.CURRENTLY_FETCHING.splice(ix, 1);
                        return resolve(r.user);
                    }
                } catch (err) {
                    if (err?.response?.status == 404) {
                        const U = {
                            _id: user_id,
                            email: "brak informacji",
                            name: "Użytkownik usunięty",
                            position: "Brak danych",
                            current_service_provider: "PRAVNA",
                            status: "deleted",
                            role: "unknown"
                        };
                        commit("addUser", U);
                        return resolve(U);
                    } else {
                        return reject(err);
                    }
                }
            });
        }
    }
};
