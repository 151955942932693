var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show)?_c('div',{staticClass:"pp-dialog"},[_c('transition',{attrs:{"name":"pp-dialog-modal","appear":""}},[(_vm.showModal)?_c('div',{staticClass:"pp-dialog__modal"}):_vm._e()]),_c('transition',{attrs:{"name":"pp-dialog-window","appear":""}},[_c('div',{staticClass:"pp-dialog__inner",on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return (function (_) {
                    if (_vm.cancelable) { _vm.$emit('close', true); }
                    else { _vm.playAnimation(); }
                }).apply(null, arguments)}}},[_c('div',{staticClass:"pp-dialog__window",class:{
                    'pp-dialog__window--animated': _vm.animation_is_playing
                },style:(Object.assign({}, (_vm.maxWidth != undefined
                        ? {
                              'max-width': _vm.maxWidth + 'px'
                          }
                        : {})))},[(!_vm.hideHeader)?_c('div',{staticClass:"pp-dialog__header"},[_c('h4',{staticClass:"pp-dialog__title text-h5"},[(_vm.hasTitleSlot)?_vm._t("title"):[_vm._v(" "+_vm._s(_vm.title)+" ")]],2),_c('button',{staticClass:"pp-dialog__close",on:{"click":function($event){return _vm.$emit('close', true)}}},[_c('PpIcon',{attrs:{"name":"close","size":14}})],1)]):_vm._e(),_c('div',{staticClass:"pp-dialog__body"},[_c('div',{ref:"scrollableWrapper",staticClass:"pp-dialog__wrapper"},[_vm._t("default")],2)]),(_vm.hasFooterSlot && !_vm.hideFooter)?_c('div',{staticClass:"pp-dialog__footer"},[_c('div',{staticClass:"pp-dialog__bodyov"}),_vm._t("footer")],2):_vm._e()])])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }