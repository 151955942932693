//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        counter: {
            type: [Number, String, Boolean],
            default: false
        },

        disabled: {
            type: Boolean,
            default: false
        },

        hint: {
            type: String,
            default: ""
        },

        id: {
            type: String,
            default: ""
        },

        label: {
            type: String,
            default: ""
        },

        placeholder: {
            type: String,
            default: ""
        },

        prefix: {
            type: String,
            default: ""
        },

        suffix: {
            type: String,
            default: ""
        },

        rules: {
            type: Array,
            default() {
                return [];
            }
        },

        type: {
            type: String,
            default: "text"
        },

        value: {
            type: [String, Number],
            default: ""
        },

        name: {
            type: String,
            default: ""
        },

        showAsterisk: {
            type: Boolean,
            deafult: false
        }
    },

    data() {
        return {
            v: "",
            local_id: "",
            error_state: false,
            focused: false
        };
    },

    computed: {
        hasLabelSlot() {
            return !!this.$slots.label;
        },
        hasHintSlot() {
            return !!this.$slots.hint;
        },
        hasPrependOuterSlot() {
            return !!this.$slots["prepend-outer"];
        },
        hasAppendOuterSlot() {
            return !!this.$slots["append-outer"];
        },
        hasPrependSlot() {
            return !!this.$slots.prepend;
        },
        hasAppendSlot() {
            return !!this.$slots.append;
        },
        isEmpty() {
            return this.v === "";
        }
    },

    watch: {
        id() {
            this.handleId();
        },
        value() {
            this.handleValue();
        }
    },

    methods: {
        test(e) {
            console.log(e);
            this.$emit("kd-enter", e);
        },
        onInput(ev) {
            this.v = ev.target.value;
            this.$emit("input", this.v);
            this.$nextTick(this.validate);
        },

        validate() {
            return this.$refs["wk-input"].validate();
        },

        resetValidation() {
            return this.$refs["wk-input"].resetValidation();
        },

        handleId() {
            if (this.id) {
                this.local_id = this.id;
            } else {
                this.local_id = "input_" + (Date.now() + Math.random() * 100000).toString(16);
            }
        },

        handleValue() {
            if (this.value != undefined) {
                this.v = this.value;
            }
        }
    },

    created() {
        this.handleId();
    },

    mounted() {
        this.handleValue();
    }
};
