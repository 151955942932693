//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    
    props: {
        dashedLine: {
            type: Boolean,
            default: false
        },
        active: {
            type: Boolean,
            default: false
        },
        completed: {
            type: Boolean,
            default: false
        }
    }

}
