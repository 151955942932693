//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data() {
        return {};
    },

    computed: {
        user() {
            return this.$store.getters["auth/getUser"];
        }
    },

    metaInfo: {
        title: "Przygotuj wniosek o upadłość konsumencką"
    }
};
