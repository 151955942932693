//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import isValidCssColor from "@/helpers/is-valid-css-color";

import ArchiveIcon from "./icons/ArchiveIcon.vue";
import AttachmentIcon from "./icons/AttachmentIcon.vue";
import BurgerIcon from "./icons/BurgerIcon.vue";
import CalendarIcon from "./icons/CalendarIcon.vue";
import ChatIcon from "./icons/ChatIcon.vue";
import ChatAltIcon from "./icons/ChatAltIcon.vue";
import CheckCircleIcon from "./icons/CheckCircleIcon.vue";
import CheckCircleFilledIcon from "./icons/CheckCircleFilledIcon.vue";
import CheckIcon from "./icons/CheckIcon.vue";
import ChevronDownIcon from "./icons/ChevronDownIcon.vue";
import ChevronLeftIcon from "./icons/ChevronLeftIcon.vue";
import ChevronRightIcon from "./icons/ChevronRightIcon.vue";
import ChevronUpIcon from "./icons/ChevronUpIcon.vue";
import CircleOutlineIcon from "./icons/CircleOutlineIcon.vue";
import CloseCircleIcon from "./icons/CloseCircleIcon.vue";
import CloseIcon from "./icons/CloseIcon.vue";
import CogIcon from "./icons/CogIcon.vue";
import DownloadIcon from "./icons/DownloadIcon.vue";
import DragIcon from "./icons/DragIcon.vue";
import ExclamationCircleIcon from "./icons/ExclamationCircleIcon.vue";
import EyeIcon from "./icons/EyeIcon.vue";
import FileAltIcon from "./icons/FileAltIcon.vue";
import FileImageIcon from "./icons/FileImageIcon.vue";
import FilePdfIcon from "./icons/FilePdfIcon.vue";
import HelpIcon from "./icons/HelpIcon.vue";
import HomeIcon from "./icons/HomeIcon.vue";
import InfoCircleIcon from "./icons/InfoCircleIcon.vue";
import LockOpenIcon from "./icons/LockOpenIcon.vue";
import LockIcon from "./icons/LockIcon.vue";
import LogoutIcon from "./icons/LogoutIcon.vue";
import MinusIcon from "./icons/MinusIcon.vue";
import PencilIcon from "./icons/PencilIcon.vue";
import PeopleIcon from "./icons/PeopleIcon.vue";
import PersonIcon from "./icons/PersonIcon.vue";
import PhoneIcon from "./icons/PhoneIcon.vue";
import PlusIcon from "./icons/PlusIcon.vue";
import PlusCircleIcon from "./icons/PlusCircleIcon.vue";
import SaveIcon from "./icons/SaveIcon.vue";
import SearchIcon from "./icons/SearchIcon.vue";
import SendIcon from "./icons/SendIcon.vue";
import SmartphoneIcon from "./icons/SmartphoneIcon.vue";
import SmileIcon from "./icons/SmileIcon.vue";
import TrashIcon from "./icons/TrashIcon.vue";
import QuestionIcon from "./icons/QuestionIcon.vue";
import UploadIcon from "./icons/UploadIcon.vue";

export default {
    components: {
        archive: ArchiveIcon,
        attachment: AttachmentIcon,
        burger: BurgerIcon,
        calendar: CalendarIcon,
        chat: ChatIcon,
        chatAlt: ChatAltIcon,
        checkCircle: CheckCircleIcon,
        checkCircleFilled: CheckCircleFilledIcon,
        check: CheckIcon,
        chevronDown: ChevronDownIcon,
        chevronLeft: ChevronLeftIcon,
        chevronRight: ChevronRightIcon,
        chevronUp: ChevronUpIcon,
        circleOutline: CircleOutlineIcon,
        closeCircle: CloseCircleIcon,
        close: CloseIcon,
        cog: CogIcon,
        download: DownloadIcon,
        drag: DragIcon,
        exclamationCircle: ExclamationCircleIcon,
        eye: EyeIcon,
        fileAlt: FileAltIcon,
        fileImage: FileImageIcon,
        filePdf: FilePdfIcon,
        help: HelpIcon,
        home: HomeIcon,
        infoCircle: InfoCircleIcon,
        lockopen: LockOpenIcon,
        lock: LockIcon,
        logout: LogoutIcon,
        minus: MinusIcon,
        pencil: PencilIcon,
        people: PeopleIcon,
        person: PersonIcon,
        phone: PhoneIcon,
        plus: PlusIcon,
        plusCircle: PlusCircleIcon,
        save: SaveIcon,
        search: SearchIcon,
        send: SendIcon,
        smartphone: SmartphoneIcon,
        smile: SmileIcon,
        trash: TrashIcon,
        question: QuestionIcon,
        upload: UploadIcon
    },

    props: {
        size: {
            type: [String, Number],
            default: 24
        },
        name: {
            type: String,
            required: true
        },
        color: {
            type: String,
            default: "currentColor"
        }
    },

    computed: {
        computedSize() {
            return parseInt(this.size);
        },
        computedColor() {
            if (isValidCssColor(this.color)) return this.color;
            const c = this.$store.getters["getColor"](this.color);
            if (c) return c;
            return "currentColor";
        },
        iconName() {
            return this.name.replace(/-./g, x => x[1].toUpperCase());
        }
    }
};
