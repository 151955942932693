//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PpContentPlaceholder from "@/components/PpContentPlaceholder";

export default {
    components: {
        PpContentPlaceholder
    },

    props: {
        user: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            default: true
        },
        size: {
            type: [Number, String],
            default: 36
        },
        onlineStatus: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        recipientInitials() {
            return this.$store.getters["auth/userInitials"](this.user);
        },
        computedSize() {
            return parseInt(this.size);
        }
    }
};
