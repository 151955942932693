//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { createPopper } from "@popperjs/core";
import EmojiPicker from "vue-emoji-picker";
import emojis from "vue-emoji-picker/src/emojis";
delete emojis["Frequently used"];

export default {
    components: {
        EmojiPicker
    },

    data() {
        return {
            dictionary: {
                categories: {
                    People: "Ludzie",
                    Nature: "Natura i zwierzęta",
                    Objects: "Przedmioty i jedzenie",
                    Places: "Miejsca i transport",
                    Symbols: "Symbole"
                }
            },

            popper_state: false,
            popper_instance: null,

            emojis
        };
    },

    watch: {
        popper_state() {
            if (this.popper_state === false && this.popper_instance != null) {
                this.destroyPopper();
            } else if (this.popper_state === true && this.popper_instance === null) {
                this.$nextTick(this.mountPopper);
            }
        }
    },

    methods: {
        mountPopper() {
            this.popper_instance = createPopper(this.$refs.invoker.$el, this.$refs.popper, {
                placement: "bottom-start",
                strategy: "fixed",
                modifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [0, 4]
                        }
                    },
                    {
                        name: "flip",
                        options: {
                            fallbackPlacements: ["top", "right", "left"]
                        }
                    },
                    {
                        name: "preventOverflow",
                        options: {
                            mainAxis: true,
                            padding: 24
                        }
                    }
                ]
            });
        },
        destroyPopper() {
            this.popper_instance.destroy();
            this.popper_instance = null;
        }
    },

    mounted() {
        this.$watch(
            "$refs.emp.display",
            nv => {
                this.popper_state = nv.visible;
            },
            {
                deep: true
            }
        );
    }
};
