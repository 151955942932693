//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },
        showModal: {
            type: Boolean,
            default: true
        },
        maxWidth: {
            type: [Number, String]
        },
        title: {
            type: String
        },
        cancelable: {
            type: Boolean,
            default: true
        },
        hideFooter: {
            type: Boolean,
            default: false
        },
        hideHeader: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            animation_is_playing: false
        };
    },

    computed: {
        hasTitleSlot() {
            return !!this.$slots.title;
        },
        hasFooterSlot() {
            return !!this.$slots.footer;
        }
    },

    watch: {
        show(nv, ov) {
            if (ov === false && nv === true) {
                document.activeElement.blur();
            }
        }
    },

    methods: {
        playAnimation() {
            if (this.animation_is_playing) return;
            this.animation_is_playing = true;
            setTimeout(_ => {
                this.animation_is_playing = false;
            }, 751);
        },

        scrollContent(to) {
            return new Promise(resolve => {
                const el = this.$refs.scrollableWrapper;
                let counter = 0;
                let target = to;
                if (to === "top") target = 0;
                else if (to === "bottom") target = el.scrollHeight;

                el.scrollTo({
                    top: target,
                    behaviour: "smooth"
                });

                function checkScroll() {
                    if (el.scrollTop == target) return resolve();
                    counter += 1;
                    if (counter <= 200) {
                        setTimeout(checkScroll, 50);
                    } else {
                        return resolve();
                    }
                }
                checkScroll();
            });
        }
    }
};
