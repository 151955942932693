var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,{tag:"component",staticClass:"pp-button",class:( _obj = {
        'pp-button--outlined': _vm.outlined && !_vm.text && !_vm.icon,
        'pp-button--text': _vm.text && !_vm.outlined && !_vm.icon,
        'pp-button--icon': _vm.icon && !_vm.outlined && !_vm.text,
        'pp-button--small': _vm.small,
        'pp-button--block': _vm.block,
        'pp-button--loading': _vm.loading,
        'pp-button--oa1': _vm.oa1,
        'pp-button--oa2': _vm.oa2
    }, _obj['pp-button--' + _vm.color] = _vm.isPresetColor(_vm.color), _obj ),style:(Object.assign({}, (!_vm.isPresetColor(_vm.color)
            ? {
                  'background-color': _vm.color,
              }
            : {}))),attrs:{"disabled":_vm.disabled,"href":_vm.tag == 'a' ? _vm.href : null},on:{"click":function (ev) { return _vm.$emit('click', ev); }}},[_c('span',{staticClass:"pp-button__content",style:(Object.assign({}, (_vm.loading
                ? {
                      opacity: 0,
                  }
                : {})))},[_vm._t("default")],2),_c('transition',{attrs:{"name":"fade"}},[(_vm.loading)?_c('PpSpinner',{attrs:{"color":_vm.outlined || _vm.text || _vm.icon ? 'currentColor' : '#fff',"size":"24","absolute":""}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }