//
//
//
//
//
//

export default {
    props: {
        item: {
            type: Object,
            required: true
        }
    }
};
