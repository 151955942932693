var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pp-alert"},[_c('div',{staticClass:"pp-alert__grid"},[_c('div',{staticClass:"pp-alert__icon",class:{
                'text-success': _vm.type == 'success',
                'text-error': _vm.type == 'error',
                'text-secondary': _vm.type == 'info'
            }},[_c('PpIcon',{attrs:{"name":_vm.iconName,"size":_vm.is_mobile ? 30 : 45}})],1),_c('div',{staticClass:"pp-alert__content"},[(_vm.hasTitleSlot || (_vm.title && _vm.title != ''))?_c('h6',{staticClass:"pp-alert__title",class:{
                    'text-h6': !_vm.is_mobile,
                    'text-body-1 text-weight-bold': _vm.is_mobile
                }},[(_vm.hasTitleSlot)?_vm._t("title"):[_vm._v(" "+_vm._s(_vm.title)+" ")]],2):_vm._e(),_c('div',{staticClass:"pp-alert__message",class:{
                    'text-body-1': !_vm.is_mobile,
                    'text-body-2': _vm.is_mobile
                }},[(_vm.hasMessageSlot)?_vm._t("message"):[_vm._v(" "+_vm._s(_vm.message)+" ")]],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }