//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PpContentPlaceholder from "@/components/PpContentPlaceholder";
import PpChatAvatar from "@/components/PpChatAvatar";
import PpChatMessage from "@/components/PpChatMessage";
import PpChatMessagesSeparator from "@/components/PpChatMessagesSeparator";
import PpEmojiPicker from "@/components/PpEmojiPicker";
import waitForMs from "../helpers/wait-for-ms";

export default {
    components: {
        PpContentPlaceholder,
        PpChatAvatar,
        PpChatMessage,
        PpChatMessagesSeparator,
        PpEmojiPicker
    },

    props: {
        application: {
            type: Object,
            required: true
        },
        recipient: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            chat_input_textarea_styles: {
                "height": "40px",
                "overflow-y": "auto"
            },
            chat_input_textarea_max_rows: 4,
            chat_input_textarea_value: "",

            show_dragover_plane: false,
            dragend_debouncer: null,

            messages_current_page: 0,
            messages_number_of_pages: 1,
            fetching_messages: false,
            sending_message: false,

            file_preview: false,
            file_preview_loading: true,
            file_preview_type: null,
            file_preview_url: null,

            mark_as_read_after_fetch: false
        };
    },

    computed: {
        recipient_user() {
            return this.$store.getters["users/getUser"](this.recipient);
        },
        user() {
            return this.$store.getters["auth/getUser"];
        },

        chat_feed() {
            function getSeparatorId() {
                return "sep_" + Date.now() + "_" + Math.floor(Math.random() * 100000);
            }

            const NEWEST = {};

            const MESSAGES = this.$store.getters["chat/getApplicationMessages"](
                this.application._id
            ).sort((a, b) => b.c_date - a.c_date);

            /*
                Konstruowanie sensownego feedu z wiadomościami - czylu wiadomości przedzielone separatorami. Sepraratory dodawane są według zasad rozpisanych poniżej w kodzie
                Wiadomości są ułożone od najnowszych na dzień dobry
            */

            const FEED = [];

            let current_creator_group_id = 0;
            let current_creator_group_count = 0;
            let current_date_group_id = 0;
            let current_date_group_count = 0;
            let current_date_group_avg = 0;

            for (let i = 0; i < MESSAGES.length; i++) {
                const ADD = {};

                // sprawdzamy, czy wiadomość jest pierwsza od danego twórcy
                if (NEWEST[MESSAGES[i].creator] === undefined && MESSAGES[i].status === "read") {
                    MESSAGES[i].newest_read_of_its_creator = true;
                    NEWEST[MESSAGES[i].creator] = MESSAGES[i]._id;
                } else {
                    MESSAGES[i].newest_read_of_its_creator = false;
                }

                // wszystkie operacje grupowe robimy od drugiego elementu, żeby móc spojrzeć 'do tyłu' dla referencji
                if (i > 0) {
                    let date_group_changed = false;

                    // 1. zmiana grupy datowanej
                    // 1.1 sprawdzamy, czy pomiędzy sąsiednimi wiadomościami nie zmieniła się data
                    const PREVIOUS_DATE = new Date(MESSAGES[i - 1].c_date);
                    const CURRENT_DATE = new Date(MESSAGES[i].c_date);
                    if (
                        PREVIOUS_DATE.getFullYear() != CURRENT_DATE.getFullYear() ||
                        PREVIOUS_DATE.getMonth() != CURRENT_DATE.getMonth() ||
                        PREVIOUS_DATE.getDate() != CURRENT_DATE.getDate()
                    ) {
                        date_group_changed = true;
                    }
                    // 1.2 jeżeli tą wiadomość od poprzedniej dzieli przynajmniej 30 minut, to dodajemy separator
                    else if (MESSAGES[i - 1].c_date - MESSAGES[i].c_date >= 30 * 60 * 1000) {
                        date_group_changed = true;
                    }

                    // 1.3. liczymy średnią czasu pomiędzy sąsiednimi wiadomościami w aktualnej grupie na bieżąco i porównujemy z nowym odstępem - to możemy zrobić tylko od trzeciej wiadomości w danej grupie
                    if (current_date_group_count >= 1) {
                        // jeżeli mamy przynajmniej drugi element w grupie, to możemy liczyć już średnią, ale nie mamy jeszcze z czym jej porównać
                        let current_diff = MESSAGES[i - 1].c_date - MESSAGES[i].c_date;

                        if (current_date_group_count === 1) {
                            // jeżeli to jest dopiero drugi, to tylko zapisujemy ten diff, jako obecną średnią
                            current_date_group_avg = current_diff;
                        } else {
                            // porównujemy aktualną średnią (już mamy tam coś zapisane) z aktualnym diffem - jeżeli średnia jest przynajmniej 4x mniejsza i diff to przynajmniej 10 minut, to robimy separator
                            if (
                                !date_group_changed &&
                                current_diff / 4 >= current_date_group_avg &&
                                current_diff >= 10 * 60 * 1000
                            ) {
                                date_group_changed = true;
                            }

                            // tak czy inaczej aktualizujemy średnią
                            current_date_group_avg =
                                (current_date_group_avg * (current_date_group_count - 1) +
                                    current_diff) /
                                current_date_group_count;
                        }
                    }

                    // 2. jeżeli zmienił się creator lub była zmiana grupy dat to robimy nową grupę, a ponieważ patrzymy od tyłu na feed, to oznaczamy też tą wiadomość jako ostatnią w grupie, a poprzednią jako pierwszą w tamtej poprzedniej grupie
                    if (date_group_changed || MESSAGES[i].creator != MESSAGES[i - 1].creator) {
                        current_creator_group_count = 0;
                        current_creator_group_id += 1;

                        ADD.last_in_creator_group = true;
                        FEED[FEED.length - 1].first_in_creator_group = true;
                    }

                    // 3. jeżeli zmieniła się grupa daty, to teraz dopiero push do FEED (inaczej nie ma gwaracji, że poprzedni element tam wrzucony jest wiadomością)
                    if (date_group_changed) {
                        current_date_group_count = 0;
                        current_date_group_id += 1;
                        current_date_group_avg = 0;

                        ADD.last_in_date_group = true;
                        FEED[FEED.length - 1].first_in_date_group = true;

                        FEED.push({
                            item_type: "separator",
                            item_data: {
                                _id: getSeparatorId(),
                                timestamp: MESSAGES[i - 1].c_date
                            }
                        });
                    }
                }

                // dodatkowe operacje
                if (i === 0) {
                    // jeżeli element jest pierwszy, to zawsze będzie ostatni w grupach
                    ADD.last_in_creator_group = true;
                    ADD.last_in_date_group = true;
                } else if (i == MESSAGES.length - 1) {
                    // jeżeli element jest ostatni, to zawsze jest pierwszy w grupach
                    ADD.first_in_creator_group = true;
                    ADD.first_in_date_group = true;
                }

                ADD.creator_group = `crg_${current_creator_group_id}`;
                ADD.date_group = `dtg_${current_date_group_id}`;

                if (ADD.last_in_creator_group === undefined) {
                    ADD.last_in_creator_group = false;
                }
                if (ADD.first_in_creator_group === undefined) {
                    ADD.first_in_creator_group = false;
                }

                if (ADD.last_in_date_group === undefined) {
                    ADD.last_in_date_group = false;
                }
                if (ADD.first_in_date_group === undefined) {
                    ADD.first_in_date_group = false;
                }

                current_creator_group_count += 1;
                current_date_group_count += 1;

                // console.log(current_creator_group_count, current_date_group_count);

                FEED.push({
                    item_type: "message",
                    item_data: MESSAGES[i],
                    ...ADD
                });

                // ostatni separator (nad pierwszą wiadomością)
                if (i == MESSAGES.length - 1) {
                    FEED.push({
                        item_type: "separator",
                        item_data: {
                            _id: getSeparatorId(),
                            timestamp: MESSAGES[i].c_date
                        }
                    });
                }
            }

            // console.log(FEED);
            return FEED;
        }
    },

    watch: {
        recipient: {
            handler: "handleRecipient",
            immediate: true
        },
        chat_feed() {
            const EL = this.$refs.messages_scrollarea;
            const WAS_ON_BOTTOM = EL.scrollHeight - EL.scrollTop - EL.offsetHeight;
            this.$nextTick(() => {
                // jeżeli chat był otwarty i zescrollowany do dołu + przyszła wiadomość od kogoś innego, to scrollujemy viewport do dołu
                if (
                    WAS_ON_BOTTOM === 0 &&
                    this.chat_feed.length > 0 &&
                    this.chat_feed[0].item_data.creator != this.user._id
                ) {
                    EL.scrollTo({
                        left: 0,
                        top: EL.scrollHeight - EL.offsetHeight
                    });
                }
            });
        }
    },

    methods: {
        handleRecipient() {
            if (!this.recipient) return;
            this.$store.dispatch("users/fetchUser", this.recipient);
        },

        // MESSAGE INPUT
        resizeChatInputTextarea() {
            this.chat_input_textarea_styles.height = "auto";
            this.$nextTick(() => {
                const realHeight = this.$refs["chat_message"].scrollHeight;
                const PADDING_SIZE = 16;
                const LINE_HEIGHT = 24;
                // console.log(realHeight);

                if (realHeight - PADDING_SIZE > LINE_HEIGHT * this.chat_input_textarea_max_rows) {
                    this.chat_input_textarea_styles.height =
                        LINE_HEIGHT * this.chat_input_textarea_max_rows + "px";
                    this.chat_input_textarea_styles["overflow-y"] = "auto";
                } else {
                    this.chat_input_textarea_styles.height = realHeight + "px";
                    this.chat_input_textarea_styles["overflow-y"] = "hidden";
                }
            });
        },
        onChatInputEnter(e) {
            if (e.shiftKey) return;
            e.preventDefault();
            this.sendMessage();
        },

        // ATTACHMENT
        onDragOver(e) {
            e.preventDefault();
            this.show_dragover_plane = true;

            if (this.dragend_debouncer != null) clearTimeout(this.dragend_debouncer);
            this.dragend_debouncer = setTimeout(() => {
                this.dragend_debouncer = null;
                this.show_dragover_plane = false;
            }, 500);
        },
        onDrop(e) {
            e.preventDefault();
            this.show_dragover_plane = false;

            if (!e.dataTransfer || !e.dataTransfer.files || e.dataTransfer.files.length == 0)
                return;

            this.handleAttachmentFiles(e.dataTransfer.files);
        },
        onAttachemntInputChange(e) {
            if (!e || !e.target || !e.target.files || e.target.files.length === 0) return;

            this.handleAttachmentFiles([e.target.files[0]]);
            this.$refs.attachment_input.value = "";
        },
        handleAttachmentFiles(files = []) {
            const ALLOWED_MIMETYPES = [
                "application/pdf",
                "image/png",
                "image/jpeg",
                "image/pjpeg",
                "image/jfif"
            ];
            const ALLOWED_EXTENSIONS = ["pdf", "png", "jpg", "jpeg"];

            const INVALID_TYPES = [];
            const TOO_LARGE = [];

            for (let i = 0; i < files.length; i++) {
                let ext = files[i].name.split(".");
                ext = ext[ext.length - 1].toLowerCase();

                if (
                    ALLOWED_EXTENSIONS.indexOf(ext) === -1 ||
                    ALLOWED_MIMETYPES.indexOf(files[i].type) === -1
                ) {
                    INVALID_TYPES.push(files[i].name);
                    continue;
                }

                if (files[i].size > 7340032) {
                    TOO_LARGE.push(files[i].name);
                    continue;
                }

                // jak git, to sendMessage
                // console.log("should send " + files[i].name);
                this.sendMessage(files[i]);
            }

            if (INVALID_TYPES.length > 0) {
                this.$message({
                    type: "error",
                    msg: `Dozwolone są tylko pliki JPG, PNG i PDF. Wybrane pliki (${INVALID_TYPES.join(
                        ", "
                    )}) nie mogły zostać przesłane.`
                });
            }
            if (TOO_LARGE.length > 0) {
                this.$message({
                    type: "error",
                    msg: `Maksymalny rozmiar pojedynczego pliku to 7MB. Wybrane pliki (${TOO_LARGE.join(
                        ", "
                    )}) nie mogły zostać przesłane.`
                });
            }
        },

        insertEmojiInChatMessage(emoji) {
            const el = this.$refs["chat_message"];

            let cursorPos = el.selectionEnd;
            this.chat_input_textarea_value =
                this.chat_input_textarea_value.substring(0, cursorPos) +
                emoji +
                this.chat_input_textarea_value.substring(cursorPos);

            // Get new cursor position
            cursorPos += emoji.length;
            this.$nextTick(() => {
                el.setSelectionRange(cursorPos, cursorPos);
            });
        },

        // MESSAGE SEND
        async sendMessage(attachment = null) {
            // if (this.sending_message) return;

            let msg_type = "text";
            if (attachment != null) msg_type = "file";

            const AXIOS_ADDITIONAL_CONFIG = {};

            // walidacja + tworzenie lokalnego obiektu
            const MSG = {
                _id:
                    "local_" +
                    msg_type +
                    (Date.now() + Math.round(Math.random() * 1000)).toString(16),
                application: this.application._id,
                creator: this.user._id,
                debtor: this.application.debtor,
                status: "created",
                c_date: Date.now()
            };

            const FD = new FormData();
            FD.append("application", MSG.application);

            if (msg_type == "text") {
                const TXT_MSG = this.chat_input_textarea_value.trim();
                if (TXT_MSG.length === 0) {
                    return this.$message({
                        type: "error",
                        msg: "Nie można przesłać pustej wiadomości"
                    });
                } else if (TXT_MSG.length > 4095) {
                    return this.$message({
                        type: "error",
                        msg: "Maksymalna długość wiadomości to 4095 znaków"
                    });
                }

                MSG.message = TXT_MSG;
                MSG.message_type = "text_message";
                FD.append("message", MSG.message);
            } else {
                FD.append("file", attachment);

                MSG.attachment = {
                    _id: "dull",
                    file_name: attachment.name,
                    file_mimetype: attachment.type,
                    file_display_type: "unknown",
                    file_size: attachment.size
                };

                AXIOS_ADDITIONAL_CONFIG.onUploadProgress = progressEvent => {
                    this.$store.commit("chat/updateMessage", {
                        id: MSG._id,
                        data: {
                            upload_progress: Math.ceil(
                                (progressEvent.loaded / progressEvent.total) * 100
                            )
                        }
                    });
                };
            }

            // this.sending_message = true;
            this.$store.commit("chat/addMessage", MSG);

            this.chat_input_textarea_value = "";

            this.$nextTick(() => {
                this.resizeChatInputTextarea();

                const W = this.$refs.messages_scrollarea;
                let top_pos = W.scrollHeight - W.offsetHeight;
                W.scrollTo({ top: top_pos, left: 0 });
            });

            this.$nextTick();

            try {
                const M = await this.$axios.$post("/chat-messages", FD, {
                    timeout: 120 * 1000,
                    ...AXIOS_ADDITIONAL_CONFIG
                });

                this.$store.commit("chat/updateMessage", {
                    id: MSG._id,
                    data: M.chat_message
                });
            } catch (err) {
                this.$store.commit("chat/removeMessage", MSG._id);
                console.error(err);
            }

            // this.sending_message = false;
        },

        // MESSAGE FETCHING
        async onIntersect() {
            if (
                this.fetching_messages ||
                this.messages_current_page >= this.messages_number_of_pages
            ) {
                return;
            }

            this.fetching_messages = true;
            this.messages_current_page += 1;
            let current_scroll_height = this.$refs.messages_scrollarea.scrollHeight;

            if (this.messages_current_page > 1) {
                await waitForMs(700);
            }

            try {
                const r = await this.$axios.$get(
                    `/chat-messages/?application=${this.application._id}&page=${this.messages_current_page}&items_per_page=24`
                );

                this.messages_current_page = r.pagination.current_page;
                this.messages_number_of_pages = r.pagination.number_of_pages;

                r.chat_messages.forEach(m => {
                    this.$store.commit("chat/addMessage", m);
                });

                // oznaczenie otrzymanych wiadomości jako dostarczone - metoda to_id więc najprostsza dla nas
                if (r.chat_messages.length > 0 && r.pagination.current_page === 1) {
                    await this.$axios.$put(`/chat-messages/batch/status`, {
                        status: "delivered",
                        to_id: r.chat_messages[0]._id
                    });
                }

                // jeżeli to było pobranie pierwszej strony + mamy info, żeby oznaczyć jako odczytane to to robimy
                if (r.pagination.current_page === 1 && this.mark_as_read_after_fetch) {
                    this.mark_as_read_after_fetch = false;
                    this.$nextTick(this.markMessagesAsRead);
                }
            } catch (err) {
                console.error(err);
            }

            this.fetching_messages = false;

            this.$nextTick(() => {
                const W = this.$refs.messages_scrollarea;
                let top_pos = W.scrollHeight - current_scroll_height;
                if (this.messages_current_page === 1) {
                    top_pos = W.scrollHeight - W.offsetHeight;
                }
                W.scrollTo({ top: top_pos, left: 0 });
            });
        },

        // FILE PREVIEW DIALOG
        openFilePreview(data) {
            this.file_preview = true;
            this.file_preview_loading = true;
            this.file_preview_type = data.file_type;

            window.addEventListener("keydown", this.filePreviewWindowClick);

            // preloading
            if (data.file_type == "image") {
                const IMG = new Image();
                IMG.onload = () => {
                    this.file_preview_url = data.url;
                    this.file_preview_loading = false;
                };
                IMG.onerror = () => {
                    this.file_preview_url = null;
                    this.file_preview_loading = false;
                };
                IMG.src = data.url;
            } else {
                this.file_preview_url = data.url;
                this.file_preview_loading = false;
            }
        },
        filePreviewWindowClick(e) {
            if (e.key == "Escape") {
                this.closeFilePreview();
            }
        },
        closeFilePreview() {
            this.file_preview = false;

            window.removeEventListener("keydown", this.filePreviewWindowClick);
        },

        // MESSAGE STATUS CHANGE
        async markMessagesAsRead() {
            if (this.chat_feed.length === 0) return;
            try {
                await this.$axios.$put(`/chat-messages/batch/status`, {
                    status: "read",
                    to_id: this.chat_feed[0].item_data._id
                });
                this.$store.state.chat.unread_count = 0;
            } catch (err) {
                console.error(err);
            }
        },

        // CHAT WINDOW EVENTS
        onChatFocus() {
            this.$store.state.chat.is_focused = true;

            // oznaczamy wszystkie wiadomości jako odczytane jeżeli są załadowane lub gdy tylko się załadują
            if (this.messages_current_page === 0 && this.chat_feed.length == 0) {
                // console.log("auto");
                this.mark_as_read_after_fetch = true;
            } else {
                // console.log("manual");
                this.markMessagesAsRead();
            }
        },
        onChatBlur() {
            this.$store.state.chat.is_focused = false;
        }
    },

    mounted() {
        this.$refs.chat_message.focus();
    }
};
