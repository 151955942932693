var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('IntersectionObserver',{on:{"enter":_vm.onIntersect}},[_c('div',{staticClass:"pp-chat-message",class:{
            'pp-chat-message--right': _vm.createdByCurrentUser,
            'pp-chat-message--expanded': _vm.show_date,
            'pp-chat-message--ficg': _vm.isFirstInCreatorGroup,
            'pp-chat-message--licg': _vm.isLastInCreatorGroup
        }},[_c('div',{staticClass:"pp-chat-message__content",class:{
                'pp-chat-message__content--padded': !_vm.createdByCurrentUser
            }},[_c('div',{staticClass:"pp-chat-message__msg",class:{ 'pp-chat-message__msg--mine': _vm.createdByCurrentUser }},[_c('div',{staticClass:"pp-chat-message__msgc",class:{
                        'pp-chat-message__msgc--mine': _vm.createdByCurrentUser,
                        'pp-chat-message__msgc--not-mine': !_vm.createdByCurrentUser,
                        'pp-chat-message__msgc--first-in-group': _vm.isFirstInCreatorGroup,
                        'pp-chat-message__msgc--last-in-group': _vm.isLastInCreatorGroup
                    },attrs:{"title":_vm.$store.getters['chat/parseDate'](_vm.item.c_date)},on:{"click":function($event){_vm.$device.mobile ? (_vm.show_date = !_vm.show_date) : false}}},[(_vm.item.message && _vm.item.message.length > 0)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.item.message.nl2br())}}):(_vm.item.attachment)?_c('div',{staticClass:"pp-chat-message-file",on:{"click":_vm.openAttachmentPreview}},[_c('div',{staticClass:"pp-chat-message-file__icon"},[(_vm.file_thumb_url === null)?_c('div',{staticClass:"pp-chat-message-file__phicon"},[_c('PpIcon',{attrs:{"name":"file-alt","size":"24"}})],1):_c('img',{staticClass:"pp-chat-message-file__image",attrs:{"src":_vm.file_thumb_url,"alt":""}})]),_c('div',{staticClass:"pp-chat-message-file__details",attrs:{"title":_vm.item.attachment.file_name}},[_vm._v(" "+_vm._s(_vm.item.attachment.file_name)+" "),_c('div',{staticClass:"pp-chat-message-file__size"},[_vm._v(" "+_vm._s(_vm.uploading_file ? "Przesyłanie pliku..." : _vm.formatFileSize(_vm.item.attachment.file_size))+" ")]),(_vm.uploading_file)?_c('ProgressBar',{staticClass:"pp-chat-message-file__progressbar",attrs:{"height":"3","color":"#BF934F","value":_vm.item.upload_progress}}):_vm._e()],1)]):_vm._e()])]),(_vm.item.attachment != undefined)?_c('div',{staticClass:"pp-chat-message__attdownload",class:{
                    'pp-chat-message__attdownload--left': _vm.createdByCurrentUser,
                    'pp-chat-message__attdownload--right': !_vm.createdByCurrentUser
                }},[_c('PpButton',{attrs:{"icon":"","color":"secondary","loading":_vm.downloading_attachment},on:{"click":_vm.downloadAttachment}},[_c('PpIcon',{attrs:{"name":"download"}})],1)],1):_vm._e(),(
                    !_vm.createdByCurrentUser && _vm.item.hide_avatar !== true && _vm.isLastInCreatorGroup
                )?_c('div',{staticClass:"pp-chat-message__user"},[_c('PpChatAvatar',{attrs:{"size":"28","loading":!_vm.message_sender,"user":_vm.message_sender ? _vm.message_sender : {}}})],1):_vm._e(),_c('div',{staticClass:"pp-chat-message__indicator",class:{
                    'pp-chat-message__indicator--right': _vm.createdByCurrentUser,
                    'pp-chat-message__indicator--left': !_vm.createdByCurrentUser
                }},[(_vm.createdByCurrentUser && _vm.item.status == 'created')?_c('span',{attrs:{"title":"Wysyłanie"}},[_c('PpIcon',{attrs:{"name":"circle-outline","size":16,"color":"primary"}})],1):(_vm.createdByCurrentUser && _vm.item.status == 'sent')?_c('span',{attrs:{"title":"Wiadomość wysłana"}},[_c('PpIcon',{attrs:{"name":"check-circle","size":16,"color":"primary"}})],1):(_vm.createdByCurrentUser && _vm.item.status == 'delivered')?_c('span',{attrs:{"title":"Wiadomość dostarczona"}},[_c('PpIcon',{attrs:{"name":"check-circle-filled","size":16,"color":"primary"}})],1):(
                        _vm.createdByCurrentUser &&
                        _vm.item.status == 'read' &&
                        _vm.isLastInCreatorGroup &&
                        _vm.item.newest_read_of_its_creator
                    )?_c('span',{attrs:{"title":'Wyświetlono - ' + _vm.$store.getters['chat/parseDate'](_vm.item.read_date)}},[_c('PpChatAvatar',{attrs:{"user":_vm.recipient,"size":"16","loading":!_vm.recipient,"online-status":false}})],1):_vm._e()])]),_c('PpCollapse',{attrs:{"show":_vm.show_date}},[_c('div',{staticClass:"pp-chat-message__msgd"},[_vm._v(" "+_vm._s(_vm.$store.getters["chat/parseDate"](_vm.item.c_date))+" ")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }