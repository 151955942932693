//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import isValidCssColor from '@/helpers/is-valid-css-color';

export default {
    props: {
        color: {
            type: String,
            default: '#fff'
        },
        background: {
            type: String,
            default: 'inherit'
        },
        size: {
            type: [Number, String],
            default: 48
        },
        absolute: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        computedBackground(){
            if(isValidCssColor(this.background)) return this.background;
            const c = this.$store.getters['getColor'](this.background);
            if(c) return c;
            return 'inherit';
        },
        computedColor(){
            if(isValidCssColor(this.color)) return this.color;
            const c = this.$store.getters['getColor'](this.color);
            if(c) return c;
            return '#656565';
        },
        computedSize(){
            return parseInt(this.size);
        }
    }
}
