//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        tag: {
            type: String,
            default: 'button',
        },
        href: {
            type: String,
            default: '',
        },
        color: {
            type: String,
            default: 'primary',
        },
        outlined: {
            type: Boolean,
            default: false,
        },
        text: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        block: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        oa1: {
            type: Boolean,
            default: false,
        },
        oa2: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        isPresetColor(color) {
            if (
                ['primary', 'secondary', 'success', 'error', 'white'].indexOf(
                    color
                ) !== -1
            )
                return true;
            return false;
        },
    },
};
