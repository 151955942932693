export default {
    namespaced: true,

    state: () => ({
        phone_is_connected: false,
        phone_id: "",
        phone_name: ""
    }),

    getters: {
        isPhoneConnected(state) {
            return state.phone_is_connected === true;
        },
        phoneName(state) {
            return state.phone_name;
        }
    },

    mutations: {
        connectPhone(state, payload) {
            state.phone_is_connected = true;
            state.phone_id = payload.sid;
            state.phone_name = payload.device;
        },
        disconenctPhone(state) {
            state.phone_is_connected = false;
            state.phone_id = null;
            state.phone_name = "";
        }
    }
};
