//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        label: {
            type: String,
            default: ""
        },
        description: {
            type: String,
            default: ""
        },
        value: {
            type: [String, Number],
            default: 0
        }
    },

    data() {
        return {};
    },

    computed: {
        computedValue() {
            return Math.max(0, Math.min(100, Math.round(parseInt(this.value))));
        }
    }
};
