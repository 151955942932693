//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        value: {
            type: [Number, String]
        },
        color: {
            type: String,
            default: "black"
        },
        height: {
            type: [Number, String],
            default: 5
        },
        indeterminate: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        parsedHeight() {
            if (isNaN(this.height)) return this.height;
            return this.height + "px";
        }
    }
};
