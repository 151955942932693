import { io } from "socket.io-client";
import store from "../store";
import { playNewNotificationSound } from "../helpers/sounds";

export default {
    install(Vue) {
        Vue.prototype.$io = io(store.state.API_BASE_URL, {
            transports: ["websocket"],
            autoConnect: false
        });

        Vue.prototype.$io.on("connect", async () => {
            await store.dispatch("auth/socketJoinRooms");
        });
        Vue.prototype.$io.on("disconnect", async reason => {
            store.state.auth.socket_room_joined = false;
            console.log(`[Socket.io] Disconnected (${reason})`);
        });
        Vue.prototype.$io.on("Remote:connected", data => {
            store.commit("tpd/connectPhone", data);
        });
        Vue.prototype.$io.on("Remote:disconnected", () => {
            store.dispatch("addMessage", {
                type: "info",
                title: "Informacja",
                msg: `Telefon ${store.getters["tpd/phoneName"]} odłączył się`
            });
            store.commit("tpd/disconenctPhone");
        });
        Vue.prototype.$io.on("User.activity_status_changed", data => {
            store.commit("users/setUserActivityStatus", data);
        });

        Vue.prototype.$io.on("ChatMessage.created", async data => {
            const UID = store?.state?.auth?.user?._id;
            if (!UID || data.creator == UID) return; // odrzucamy swoje wiadomości

            const APP_ID = store?.state?.application?.application?._id;
            if (!APP_ID || data.application != APP_ID) return; // nie ma prawa się wydarzyć jeżeli backend nie odwali czegoś

            store.commit("chat/addMessage", data);

            try {
                // oznaczenie nowododanej wiadomości jako dostarczonej
                await Vue.prototype.$axios.$put(`/chat-messages/batch/status`, {
                    status: "delivered",
                    application: data.application,
                    ids: [data._id]
                });

                // jeżeli czat jest aktywny, to od razu też jako odczytaną
                if (store.state.chat.is_focused) {
                    await Vue.prototype.$axios.$put(`/chat-messages/batch/status`, {
                        status: "read",
                        application: data.application,
                        ids: [data._id]
                    });
                } else {
                    // jeżeli czat nie jest otwarty, to dajemy sygnał dźwiękowy
                    playNewNotificationSound();
                    store.state.chat.unread_count += 1;
                }
            } catch (err) {
                console.error(err);
            }
        });

        Vue.prototype.$io.on("ChatMessage.status_updated", async data => {
            // console.log(data);
            if (data.update_mode === "by_id") {
                for (let i = 0; i < data.ids.length; i++) {
                    const M = store.getters["chat/getMessage"](data.ids[i]);
                    if (!M) continue;

                    store.commit("chat/updateMessage", {
                        id: M._id,
                        data: data.updated_data
                    });
                }
            } else if (data.update_mode === "by_c_date") {
                for (let i = 0; i < store.state.chat.messages.length; i++) {
                    if (
                        store.state.chat.messages[i].application != data.application ||
                        (data.creator == "NOT_USER" &&
                            store.state.chat.messages[i].creator == store.state.auth.user._id) ||
                        (data.creator == "USER" &&
                            store.state.chat.messages[i].creator != store.state.auth.user._id)
                    ) {
                        continue;
                    }

                    store.state.chat.messages.splice(i, 1, {
                        ...store.state.chat.messages[i],
                        ...data.updated_data
                    });
                }
            }
        });
    }
};
