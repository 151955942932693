import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import E404 from "../views/Error404.vue";
import store from "../store";
import waitForMs from "../helpers/wait-for-ms";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        component: Home
    },
    {
        path: "/faq",
        name: "faq",
        component: () => import(/* webpackChunkName: "faq" */ "../views/Faq.vue")
    },

    // AUTH ROUTES
    {
        path: "/auth/logowanie",
        name: "auth-login",
        component: () => import(/* webpackChunkName: "auth" */ "../views/auth/login.vue")
    },
    {
        path: "/auth/rejestracja",
        name: "auth-register",
        component: () => import(/* webpackChunkName: "auth" */ "../views/auth/register.vue")
    },
    {
        path: "/auth/odblokowanie-konta",
        name: "auth-unlock",
        component: () => import(/* webpackChunkName: "auth" */ "../views/auth/unlock.vue")
    },
    {
        path: "/auth/nie-pamietam-hasla",
        name: "auth-password-reset-request",
        component: () =>
            import(/* webpackChunkName: "auth" */ "../views/auth/password-reset-request.vue")
    },
    {
        path: "/auth/resetowanie-hasla",
        name: "auth-password-reset",
        component: () => import(/* webpackChunkName: "auth" */ "../views/auth/password-reset.vue")
    },

    // MY ACCOUNT
    // {
    //     path: '/moje-konto',
    //     name: 'my-account__main',
    //     component: () => import(/* webpackChunkName: "my-account" */ '../views/my-account/index.vue')
    // },

    // WNIOSEK
    {
        path: "/wprowadzenie",
        name: "onboarding",
        component: () => import(/* webpackChunkName: "onboarding" */ "../views/Onboarding.vue")
    },

    {
        path: "/moje-dane",
        name: "debtor-data",
        component: () =>
            import(/* webpackChunkName: "debtor-data" */ "../views/DebtorData/Index.vue")
    },
    {
        path: "/moje-dane/imie-i-nazwisko",
        name: "debtor-data-step1",
        component: () =>
            import(/* webpackChunkName: "debtor-data" */ "../views/DebtorData/Step1.vue")
    },

    {
        path: "/moje-dane/adres-email",
        name: "debtor-data-step2",
        component: () =>
            import(/* webpackChunkName: "debtor-data" */ "../views/DebtorData/Step2.vue")
    },

    {
        path: "/moje-dane/pesel",
        name: "debtor-data-step3",
        component: () =>
            import(/* webpackChunkName: "debtor-data" */ "../views/DebtorData/Step3.vue")
    },

    {
        path: "/moje-dane/nip",
        name: "debtor-data-step4",
        component: () =>
            import(/* webpackChunkName: "debtor-data" */ "../views/DebtorData/Step4.vue")
    },

    {
        path: "/moje-dane/numer-telefonu",
        name: "debtor-data-step5",
        component: () =>
            import(/* webpackChunkName: "debtor-data" */ "../views/DebtorData/Step5.vue")
    },

    {
        path: "/moje-dane/data-urodzenia",
        name: "debtor-data-step6",
        component: () =>
            import(/* webpackChunkName: "debtor-data" */ "../views/DebtorData/Step6.vue")
    },

    {
        path: "/moje-dane/adres-zamieszkania",
        name: "debtor-data-step7",
        component: () =>
            import(/* webpackChunkName: "debtor-data" */ "../views/DebtorData/Step7.vue")
    },

    {
        path: "/moje-dane/adres-do-korespondencji",
        name: "debtor-data-step8",
        component: () =>
            import(/* webpackChunkName: "debtor-data" */ "../views/DebtorData/Step8.vue")
    },

    {
        path: "/moje-dane/dane-uzytkownika",
        name: "debtor-data-step9",
        component: () =>
            import(/* webpackChunkName: "debtor-data" */ "../views/DebtorData/Step9.vue")
    },

    {
        path: "/moje-dane/potwierdzenie-danych",
        name: "debtor-data-step10",
        component: () =>
            import(/* webpackChunkName: "debtor-data" */ "../views/DebtorData/Step10.vue")
    },

    {
        path: "/ankieta",
        name: "survey",
        component: () => import(/* webpackChunkName: "survey" */ "../views/Survey.vue")
    },

    {
        path: "/skladniki-majatku",
        name: "property-components",
        component: () =>
            import(/* webpackChunkName: "property-components" */ "../views/PropertyComponents.vue")
    },

    {
        path: "/wydatki-cykliczne",
        name: "recurring-expenses",
        component: () =>
            import(/* webpackChunkName: "recurring-expenses" */ "../views/RecurringExpenses.vue")
    },

    {
        path: "/twoi-wierzyciele",
        name: "debts",
        component: () => import(/* webpackChunkName: "debts" */ "../views/Debts.vue")
    },

    {
        path: "/twoje-dokumenty",
        name: "documents",
        component: () => import(/* webpackChunkName: "documents" */ "../views/Documents.vue")
    },

    {
        path: "/przetwarzanie-wniosku",
        name: "application-processing",
        component: () =>
            import(
                /* webpackChunkName: "application-general" */ "../views/ApplicationProcessing.vue"
            )
    },
    {
        path: "/twoj-wniosek",
        name: "application-ready",
        component: () =>
            import(/* webpackChunkName: "application-general" */ "../views/ApplicationReady.vue")
    },

    // public bin
    {
        path: "/pb/oplac-wniosek",
        name: "pb-pay",
        component: () => import(/* webpackChunkName: "pb" */ "../views/pb/Pay.vue")
    },
    {
        path: "/pb/platnosci/blad-platnosci",
        name: "pb-payment-error",
        component: () => import(/* webpackChunkName: "pb" */ "../views/pb/PaymentError.vue")
    },
    {
        path: "/pb/platnosci/platnosc-wykonana",
        name: "pb-payment-success",
        component: () => import(/* webpackChunkName: "pb" */ "../views/pb/PaymentSuccess.vue")
    },
    {
        path: "/pb/przesylanie-zdjec",
        name: "pb-upload-photos",
        component: () => import(/* webpackChunkName: "pb" */ "../views/pb/UploadPhotosByTpd.vue")
    },

    // redirects
    {
        path: "/r/:hash",
        name: "redirect",
        component: () => import(/* webpackChunkName: "redirect" */ "../views/r/Hash.vue")
    },

    {
        path: "*",
        name: "error-404",
        component: E404
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        window.scrollTo(0, 0);
        // return { top: 0 };
    }
});

const UNPROTECTED_ROUTES = [
    "auth-register",
    "auth-login",
    "auth-unlock",
    "auth-password-reset",
    "auth-password-reset-request",
    "pb-pay",
    "pb-payment-error",
    "pb-payment-success",
    "pb-upload-photos",
    "redirect"
];

router.beforeEach(async (to, from, next) => {
    // jeżeli apka się jeszcze nie zbootowała to grzecznie czekamy
    while (!store.state.app_booted) {
        await waitForMs(250);
    }

    // jeżeli użytkownik próbuje się dostać na zabezpieczony route to sprawdzamy jego uprawnienia - musi być tylko zalogowany i role == user, resztę załatwi UI i 401
    if (
        UNPROTECTED_ROUTES.indexOf(to.name) == -1 &&
        (!store.getters["auth/isLoggedIn"] ||
            (store.getters["auth/isLoggedIn"] && store.getters["auth/userRole"] != "user"))
    ) {
        console.log(to);
        return next("/auth/logowanie?rback=" + encodeURIComponent(to.fullPath));
    }

    return next();
});

export default router;
