export default {
    namespaced: true,

    state: () => ({
        messages: [],
        is_focused: false,
        unread_count: 0
    }),

    getters: {
        getApplicationMessages: state => application => {
            return state.messages.filter(m => m.application == application);
        },
        getMessage: state => id => {
            return state.messages.find(el => el._id == id);
        },

        // zwraca string z datą i/lub godziną w zależności od tego jak w stosunku do teraz plasuje się zadana data
        parseDate:
            state =>
            (d, opts = {}) => {
                if (typeof d != "number") {
                    d = new Date(d).getTime();
                }

                const now = Date.now();

                if (now - d <= 0) {
                    return "Invalid input date";
                }

                const now_date = new Date(now);
                const d_date = new Date(d);

                const weekdays = [
                    {
                        full: "Niedziela",
                        short: "nd."
                    },
                    {
                        full: "Poniedziałek",
                        short: "pn."
                    },
                    {
                        full: "Wtorek",
                        short: "wt."
                    },
                    {
                        full: "Środa",
                        short: "śr."
                    },
                    {
                        full: "Czwartek",
                        short: "czw."
                    },
                    {
                        full: "Piątek",
                        short: "pt."
                    },
                    {
                        full: "Sobota",
                        short: "sob."
                    }
                ];

                // 1. jeżeli między datami jest < 24h różnicy i jednocześnie jest to ta sama data to zwracamy sam stamp godzinowy
                if (now - d < 24 * 60 * 60 * 1000 && now_date.getDate() === d_date.getDate()) {
                    return `${d_date.getHours().toString().pad(2)}:${d_date
                        .getMinutes()
                        .toString()
                        .pad(2)}`;
                }

                // 2. jeżeli jest mniej niż 7 dni różnicy i jest to jednocześnie ten sam tydzień to zwracamy skróconą nazwę dnia tygodnia + godzinę
                if (now - d < 7 * 24 * 60 * 60 * 1000 && now_date.getWeek() == d_date.getWeek()) {
                    return `${weekdays[d_date.getDay()].short} ${d_date
                        .getHours()
                        .toString()
                        .pad(2)}:${d_date.getMinutes().toString().pad(2)}`;
                }

                // 3. jeżeli poprzednie przypadki nie złapały to zwracamy według przekazanych opcji
                if (opts.hide_time === true) {
                    return `${d_date.getDate().toString().pad(2)}.${(d_date.getMonth() + 1)
                        .toString()
                        .pad(2)}.${d_date.getFullYear()}`;
                }
                return `${d_date.getDate().toString().pad(2)}.${(d_date.getMonth() + 1)
                    .toString()
                    .pad(2)}.${d_date.getFullYear()} ${d_date.getHours().toString().pad(2)}:${d_date
                    .getMinutes()
                    .toString()
                    .pad(2)}`;
            }
    },

    mutations: {
        addMessage(state, message) {
            const ix = state.messages.findIndex(i => i._id == message._id);
            if (ix === -1) {
                state.messages.push(message);
            }
        },
        updateMessage(state, { id, data }) {
            const ix = state.messages.findIndex(i => i._id == id);
            if (ix === -1) {
                return console.error("Could not find ChatMessage with given ID");
            }

            state.messages.splice(ix, 1, {
                ...state.messages[ix],
                ...data
            });
        },
        removeMessage(state, id) {
            const ix = state.messages.findIndex(i => i._id == id);
            if (ix === -1) {
                return console.error("Could not find ChatMessage with given ID");
            }

            state.messages.splice(ix, 1);
        }
    },

    actions: {}
};
