//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    methods: {
        handleScrollable() {
            const x = this.$refs.content;
            if (!x) return;

            if (x.scrollHeight > x.offsetHeight) {
                this.$store.commit("wrappers/BaseContentWrapperIsScrollable", true);
            } else {
                this.$store.commit("wrappers/BaseContentWrapperIsScrollable", false);
            }
        },
        handleScroll() {
            const x = this.$refs.content;
            if (!x) return;
            this.$store.commit("wrappers/BaseContentWrapperScrollValue", x.scrollTop);
        },
        scrollToTop() {
            return new Promise(resolve => {
                const cnt = this.$refs.content;

                cnt.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth"
                });

                function checkScroll() {
                    if (cnt.scrollTop == 0) return resolve();
                    else {
                        setTimeout(checkScroll, 100);
                    }
                }
                checkScroll();
            });
        }
    },

    mounted() {
        window.addEventListener("resize", this.handleScrollable, { passive: true });
        this.handleScrollable();
        this.handleScroll();
    },

    beforeDestroy() {
        window.removeEventListener("resize", this.handleScrollable, { passive: true });
    }
};
