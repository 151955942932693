var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stepper-content-wrapper"},[_c('div',{staticClass:"stepper-content-wrapper__header",class:{
            'stepper-content-wrapper__header--elevated':
                _vm.$store.getters['wrappers/BaseContentWrapperIsScrolled']
        }},[(_vm.label != '')?_c('h4',{staticClass:"stepper-content-wrapper__label"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"stepper-content-wrapper-pbar"},[_c('div',{class:{
                    'stepper-content-wrapper-pbar__inner': true,
                    'stepper-content-wrapper-pbar__inner--active':
                        _vm.computedValue > 0 && _vm.computedValue < 98
                },style:({
                    width: (_vm.computedValue + "%")
                })})]),(_vm.description != '')?_c('div',{staticClass:"stepper-content-wrapper__description text-h7"},[_vm._v(" "+_vm._s(_vm.description)+" ")]):_vm._e()]),_c('div',{staticClass:"stepper-content-wrapper__content"},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }