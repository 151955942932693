export default {

    namespaced: true,

    state: () => ({

        base_content_wrapper_is_scrollable: false,
        base_content_wrapper_scroll_value: 0

    }),

    getters: {

        BaseContentWrapperIsScrollable: state => state.base_content_wrapper_is_scrollable,
        BaseContentWrapperIsScrolled: state => state.base_content_wrapper_scroll_value > 0,
        BaseContentWrapperScrollValue: state => state.base_content_wrapper_scroll_value
        
    },

    mutations: {

        BaseContentWrapperIsScrollable(state, value){
            state.base_content_wrapper_is_scrollable = !!value;
        },
        BaseContentWrapperScrollValue(state, value){
            state.base_content_wrapper_scroll_value = parseInt(value);
        }

    }
}