//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    
    props: {

        disabled: {
            type: Boolean,
            default: false
        },

        hint: {
            type: String,
            default: ''
        },

        id: {
            type: String,
            default: ''
        },

        label: {
            type: String,
            default: ''
        },

        rules: {
            type: Array,
            default(){
                return [];
            }
        },

        value: {
            type: [String, Number, Boolean, Array],
            default: ''
        },

        name: {
            type: String,
            default: ''
        },

        trueValue: {
            type: [Number, String, Boolean],
            default: true
        },

        falseValue: {
            type: [Number, String, Boolean],
            default: false
        },

        multiple: {
            type: Boolean,
            default: false
        },

        tabindex: {
            type: [String, Number],
            default: '0'
        }

    },

    data(){
        return {
            local_id: '',
            error_state: false
        }
    },

    computed: {
        hasLabelSlot(){
            return !!this.$slots.label;
        },
        isChecked(){
            if(!this.multiple) return this.value === this.trueValue;
            else return Array.isArray(this.value) && this.value.indexOf(this.trueValue) !== -1;
        }
    },

    watch: {
        id(){
            this.handleId();
        }
    },

    methods: {

        toggle(){
            if(this.disabled) return;

            let nv = null;
            if(this.isChecked){

                if(this.multiple){

                    nv = JSON.parse(JSON.stringify(this.value));
                    nv.splice(nv.indexOf(this.trueValue), 1);
                }
                else{
                    nv = this.falseValue;
                }
            }
            else{

                if(this.multiple){

                    nv = JSON.parse(JSON.stringify(this.value));
                    nv.push(this.trueValue);
                }
                else{
                    nv = this.trueValue;
                }
            }

            this.$emit('input', nv);
            this.$emit('change', nv);
            this.$nextTick(this.validate);
        },

        validate(){
            return this.$refs['wk-input'].validate();
        },

        resetValidation(){
            return this.$refs['wk-input'].resetValidation();
        },

        handleId(){
            if(this.id){
                this.local_id = this.id;
            }
            else{
                this.local_id = 'cbox_' + (Date.now() + Math.random() * 100000).toString(16);
            }
        }
    },

    created(){
        this.handleId();
    }

}
