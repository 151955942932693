//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import WkSelectElement from "./WkSelectElement.vue";

export default {
    components: {
        WkSelectElement
    },

    props: {
        disabled: {
            type: Boolean,
            default: false
        },

        hint: {
            type: String,
            default: ""
        },

        label: {
            type: String,
            default: ""
        },

        placeholder: {
            type: String,
            default: "Wybierz jedną z opcji"
        },

        rules: {
            type: Array,
            default() {
                return [];
            }
        },

        value: {
            type: [String, Number, Array, Boolean],
            default: ""
        },

        items: {
            type: Array,
            default() {
                return [];
            }
        },

        multiple: {
            type: Boolean,
            default: false
        },

        showAsterisk: {
            type: Boolean,
            deafult: false
        },

        emptyListMsg: {
            type: String,
            deafult: "Options list is empty"
        }
    },

    data() {
        return {
            error_state: false,
            focused: false,

            input_width: 300
        };
    },

    computed: {
        hasLabelSlot() {
            return !!this.$slots.label;
        },
        hasHintSlot() {
            return !!this.$slots.hint;
        },
        hasPrependOuterSlot() {
            return !!this.$slots["prepend-outer"];
        },
        hasAppendOuterSlot() {
            return !!this.$slots["append-outer"];
        },

        hasItemSlot() {
            return !!this.$scopedSlots.item;
        },
        hasPrependItemSlot() {
            return !!this.$slots["prepend-item"];
        },
        hasAppendItemSlot() {
            return !!this.$slots["append-item"];
        },
        hasSelectionSlot() {
            return !!this.$scopedSlots.selection;
        }
    },

    methods: {
        validate() {
            return this.$refs["wk-input"].validate();
        },

        resetValidation() {
            return this.$refs["wk-input"].resetValidation();
        },

        doCalcs() {
            this.input_width = this.$refs["wk-input"].$el.offsetWidth;
        }
    },

    mounted() {
        this.doCalcs();
        window.addEventListener("resize", this.doCalcs, { passive: true });
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.doCalcs, { passive: true });
    },
    updated() {
        this.doCalcs();
    }
};
