export default {
    namespaced: true,

    state: () => ({
        TYPES_OF_DOCUMENTS: {
            debtor_bik: "BIK",
            debtor_krz: "KRZ",
            debtor_esad: "EPU",
            debtor_childs: "Dzieci",
            debtor_marriage: "Małżeństwo",
            debtor_business: "Działalność gospodarcza",
            debtor_employment: "Zatrudnienie",
            debtor_health: "Zdrowie",
            debtor_c19_impact: "Wpływ pandemii",
            debtor_l12m_transactions: "Transakcje z ostatnich 12 miesięcy",
            debtor_incurring_debts_moment: "Moment zaciągania długów",
            debtor_borrowed_funds_allocation: "Przeznaczenie pożyczonych środków",
            debtor_shareholding: "Odpowiedzialność za długi spółki",
            debtor_l10y_bankrupcy_application: "Poprzedni wniosek o Upadłość Konsumencką",
            debtor_creditors_detriment: "Działania na szkodę wierzycieli",
            debtor_real_estate: "Posiadana nieruchomość",
            debtor_flat_rent: "Wynajem mieszkania",
            other: "Inne"
        },
        RECURRING_EXPENSE_TYPES: {
            RENT: "czynsz",
            GAS: "gaz",
            ELECTRICITY: "prad",
            WATER: "woda",
            INTERNET: "internet",
            FOOD: "zywnosc",
            CLOTHING: "odziez_i_obuwie",
            HEATING_FUEL: "opal_grzewczy",
            HOUSEHOLD_SHOPPING: "zakupy_do_gospodarstwa_domowego",
            MEDICINES: "leki",
            TELEPHONE_SUBSCRIPTION: "abonament_telefoniczny",
            NECESSARY_COMMUTING: "niezbedne_przejazdy",
            OTHER: "inne"
        },
        RECURRING_EXPENSE_TYPES_LABELS: {
            ["czynsz"]: "Czynsz",
            ["gaz"]: "Gaz",
            ["prad"]: "Prąd",
            ["woda"]: "Woda",
            ["internet"]: "Internet",
            ["zywnosc"]: "Żywność",
            ["odziez_i_obuwie"]: "Odzież i obuwie",
            ["opal_grzewczy"]: "Opał grzewczy",
            ["zakupy_do_gospodarstwa_domowego"]: "Zakupy gospodarstwa domowego",
            ["leki"]: "Leki",
            ["abonament_telefoniczny"]: "Abonament telefoniczny",
            ["niezbedne_przejazdy"]: "Niezbędne przejazdy",
            ["inne"]: "Inne"
        }
    }),

    getters: {},

    mutations: {}
};
