var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('WkInput',{ref:"wk-input",attrs:{"hint":_vm.hint,"label":_vm.label,"rules":_vm.rules,"value":_vm.value,"show-asterisk":_vm.showAsterisk,"error":_vm.error_state},on:{"click:prepend-outer":function (e) { return _vm.$emit('click:prepend-outer', e); },"click:append-outer":function (e) { return _vm.$emit('click:append-outer', e); },"update:error":function($event){_vm.error_state=$event}},scopedSlots:_vm._u([{key:"label",fn:function(){return [(_vm.hasLabelSlot)?_vm._t("label"):_vm._e()]},proxy:true},{key:"hint",fn:function(){return [(_vm.hasHintSlot)?_vm._t("hint"):_vm._e()]},proxy:true},{key:"prepend-outer",fn:function(){return [(_vm.hasPrependOuterSlot)?_vm._t("prepend-outer"):_vm._e()]},proxy:true},{key:"append-outer",fn:function(){return [(_vm.hasAppendOuterSlot)?_vm._t("append-outer"):_vm._e()]},proxy:true}],null,true)},[_c('div',{ref:"main",staticClass:"wk-select",class:{
            'wk-select--disabled': _vm.disabled,
            'wk-select--focused': _vm.focused,
            'wk-select--invalid': _vm.error_state
        },attrs:{"tabindex":"0"},on:{"click":function($event){$event.stopPropagation();return _vm.$refs['input'].openMenu()},"keypress":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$refs['input'].openMenu()},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }return _vm.$refs['input'].openMenu()}],"focus":function($event){$event.stopPropagation();return _vm.$refs['input'].focus()},"blur":function($event){$event.stopPropagation();return _vm.$refs['input'].blur()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return _vm.$refs.input.closeMenu()}}},[_c('WkSelectElement',{ref:"input",attrs:{"items":_vm.items,"multiple":_vm.multiple,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"value":_vm.value,"empty-list-msg":_vm.emptyListMsg,"error":_vm.error_state,"popper-distance":"8","dropdown-width":_vm.input_width},on:{"focus":function($event){_vm.focused = true},"blur":function($event){_vm.focused = false},"input":function (v) {
                    _vm.$emit('input', v);
                    _vm.$nextTick(_vm.validate);
                },"change":function (v) { return _vm.$emit('change', v); }},scopedSlots:_vm._u([(_vm.hasPrependItemSlot)?{key:"prepend-item",fn:function(){return [_vm._t("prepend-item")]},proxy:true}:null,(_vm.hasItemSlot)?{key:"item",fn:function(){return [_vm._t("item")]},proxy:true}:null,(_vm.hasAppendItemSlot)?{key:"append-item",fn:function(){return [_vm._t("append-item")]},proxy:true}:null,(_vm.hasSelectionSlot)?{key:"selection",fn:function(){return [_vm._t("selection")]},proxy:true}:null],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }