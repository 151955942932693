export function saveInLocalStorage(key, value) {
    if (window.localStorage) {
        localStorage.setItem(key, value);
    }
}

export function retrieveFromLocalStorage(key) {
    if (window.localStorage) {
        return localStorage.getItem(key);
    }
    return null;
}

export function removeFromLocalStorage(key) {
    if (window.localStorage) {
        return localStorage.removeItem(key);
    }
}
