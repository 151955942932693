var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wk-input"},[(_vm.hasLabelSlot || _vm.label != '')?_c('label',{staticClass:"wk-input__label",style:({
            'max-width': _vm.main_width + 'px',
            'margin-left':
                _vm.prepend_outer_width == 'auto'
                    ? 'auto'
                    : _vm.prepend_outer_width + 'px'
        }),attrs:{"for":_vm.id}},[_c('div',{staticClass:"wk-input__label-content"},[(_vm.hasLabelSlot)?_vm._t("label"):(_vm.label != '')?[_vm._v(" "+_vm._s(_vm.label)+" ")]:_vm._e()],2),(_vm.showAsterisk)?_c('span',{staticClass:"wk-input__asterisk"},[_vm._v(" * ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"wk-input__grid"},[(_vm.hasPrependOuterSlot)?_c('div',{ref:"prepend-outer",staticClass:"wk-input__prepend-outer",on:{"click":function($event){$event.stopPropagation();return (function (e) { return _vm.$emit('click:prepend-outer', e); }).apply(null, arguments)}}},[_vm._t("prepend-outer")],2):_vm._e(),_c('div',{ref:"main",staticClass:"wk-input__main"},[_vm._t("default")],2),(_vm.hasAppendOuterSlot)?_c('div',{staticClass:"wk-input__append-outer",on:{"click":function($event){$event.stopPropagation();return (function (e) { return _vm.$emit('click:append-outer', e); }).apply(null, arguments)}}},[_vm._t("append-outer")],2):_vm._e()]),(
            (!_vm.error && (_vm.hasHintSlot || _vm.hint != '')) ||
            (_vm.error && _vm.msg != '') ||
            _vm.counterValue != false
        )?_c('div',{staticClass:"wk-input__messages",style:({
            'max-width': _vm.main_width + 'px',
            'margin-left':
                _vm.prepend_outer_width == 'auto'
                    ? 'auto'
                    : _vm.prepend_outer_width + 'px'
        })},[_c('transition',{attrs:{"name":"wk-input-messages","mode":"out-in","appear":""}},[(!_vm.error)?_c('div',{key:"hint",staticClass:"wk-input__message wk-input__hint"},[(_vm.hasHintSlot)?_vm._t("hint"):[_vm._v(" "+_vm._s(_vm.hint)+" ")]],2):(_vm.error && _vm.msg != '')?_c('div',{key:"msg",staticClass:"wk-input__message wk-input__errormsg"},[_vm._v(" "+_vm._s(_vm.msg)+" ")]):_vm._e()]),(_vm.counterValue !== false)?_c('div',{staticClass:"wk-input__counter"},[_vm._v(" "+_vm._s(_vm.value.length)+"/"+_vm._s(_vm.counterValue)+" ")]):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }