var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('WkInput',{ref:"wk-input",attrs:{"counter":_vm.counter,"hint":_vm.hint,"label":_vm.label,"id":_vm.local_id,"rules":_vm.rules,"value":_vm.v,"show-asterisk":_vm.showAsterisk,"error":_vm.error_state},on:{"click:prepend-outer":function (e) { return _vm.$emit('click:prepend-outer', e); },"click:append-outer":function (e) { return _vm.$emit('click:append-outer', e); },"update:error":function($event){_vm.error_state=$event}},scopedSlots:_vm._u([{key:"label",fn:function(){return [(_vm.hasLabelSlot)?_vm._t("label"):_vm._e()]},proxy:true},{key:"hint",fn:function(){return [(_vm.hasHintSlot)?_vm._t("hint"):_vm._e()]},proxy:true},{key:"prepend-outer",fn:function(){return [(_vm.hasPrependOuterSlot)?_vm._t("prepend-outer"):_vm._e()]},proxy:true},{key:"append-outer",fn:function(){return [(_vm.hasAppendOuterSlot)?_vm._t("append-outer"):_vm._e()]},proxy:true}],null,true)},[_c('div',{ref:"main",staticClass:"wk-textarea",class:{
            'wk-textarea--disabled': _vm.disabled,
            'wk-textarea--focused': _vm.focused,
            'wk-textarea--invalid': _vm.error_state
        },on:{"click":function($event){$event.stopPropagation();return _vm.$refs['input'].focus()}}},[(_vm.hasPrependSlot)?_c('div',{staticClass:"wk-textarea__prepend",on:{"click":function($event){$event.stopPropagation();return (function (e) { return _vm.$emit('click:prepend', e); }).apply(null, arguments)}}},[_vm._t("prepend")],2):_vm._e(),(_vm.prefix != '')?_c('div',{staticClass:"wk-textarea__prefix"},[_vm._v(" "+_vm._s(_vm.prefix)+" ")]):_vm._e(),_c('div',{staticClass:"wk-textarea__tfield",class:{
                'wk-textarea__tfield--autogrow': _vm.autogrow
            },style:({
                '--wktxa-rows': _vm.maxRows,
                '--wktxa-min-rows': _vm.minRows
            })},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.v),expression:"v"}],ref:"input",staticClass:"wk-textarea__input",style:(_vm.autogrow ? _vm.textarea_styles : {}),attrs:{"disabled":_vm.disabled,"id":_vm.local_id,"name":_vm.name,"rows":_vm.autogrow ? 1 : _vm.maxRows},domProps:{"value":(_vm.v)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.v=$event.target.value},function (_) {
                        _vm.resizeTextarea();
                    }],"focus":function($event){_vm.focused = true},"blur":function($event){_vm.focused = false}}}),_vm._v(" "),(!_vm.focused && _vm.v == '')?_c('div',{staticClass:"wk-textarea__placeholder"},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]):_vm._e()]),(_vm.suffix != '')?_c('div',{staticClass:"wk-textarea__suffix"},[_vm._v(" "+_vm._s(_vm.suffix)+" ")]):_vm._e(),(_vm.hasAppendSlot)?_c('div',{staticClass:"wk-textarea__append",on:{"click":function($event){$event.stopPropagation();return (function (e) { return _vm.$emit('click:append', e); }).apply(null, arguments)}}},[_vm._t("append")],2):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }