var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('emoji-picker',{ref:"emp",on:{"emoji":function (e) { return _vm.$emit('emoji', e); }},scopedSlots:_vm._u([{key:"emoji-invoker",fn:function(ref){
var clickEvent = ref.events.click;
return _c('PpButton',{ref:"invoker",staticClass:"px-0 ppep__invoker",attrs:{"text":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return (function (e) {
                clickEvent(e);
            }).apply(null, arguments)}}},[_c('PpIcon',{attrs:{"name":"smile","size":"28"}})],1)}},{key:"emoji-picker",fn:function(ref){
            var insert = ref.insert;
            var display = ref.display;
return _c('div',{},[_c('div',{ref:"popper",staticClass:"ppep-window",attrs:{"data-visible":display.visible}},[_c('div',{staticClass:"ppep-window__inner"},_vm._l((_vm.emojis),function(emojiGroup,category){return _c('div',{key:category,staticClass:"ppep-window__category"},[_c('h5',{staticClass:"ppep-window__catname text-body-2 text-uppercase grey--text"},[_vm._v(" "+_vm._s(_vm.dictionary.categories[category])+" ")]),_c('div',{staticClass:"ppep-window__emgrid"},_vm._l((emojiGroup),function(emoji,emojiName){return _c('span',{key:emojiName,staticClass:"ppep-window__emoji",attrs:{"title":emojiName},on:{"click":function($event){return insert(emoji)}}},[_vm._v(_vm._s(emoji))])}),0)])}),0)])])}}])})}
var staticRenderFns = []

export { render, staticRenderFns }