//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PpAlert from '@/components/PpAlert';

export default {

    components: {
        PpAlert
    },
    
    computed: {
        messages(){
            return this.$store.state.messages;
        }
    }
}
