//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LayoutMessages from "@/components/LayoutMessages";

export default {
    name: "App",

    components: {
        LayoutMessages
    },

    data: () => ({
        layout: "div",
        online_status: true
    }),

    computed: {
        appBooted() {
            return this.$store.state.app_booted;
        }
    },

    watch: {
        layout(nv, ov) {
            if (ov === "div") {
                this.$store.state.initial_layout_set = true;
            }
        }
    },

    methods: {
        async testConnection(afterLost = false) {
            try {
                const r = await this.$axios.$get("/test");
                if (r && r.success == true) {
                    if (afterLost)
                        this.$store.dispatch({
                            type: "success",
                            message: "Aplikacja odzyskała połączenie z internetem"
                        });
                    this.online_status = true;
                } else {
                    this.online_status = false;
                    setTimeout(() => {
                        if (!this.online_status) this.testConnection(afterLost);
                    }, 5000);
                }
            } catch (err) {
                console.error(err);
                this.online_status = false;
                setTimeout(() => {
                    if (!this.online_status) this.testConnection(afterLost);
                }, 5000);
            }
        },

        redirectUserToCurrentApplicationStepIfNeeded(force = false) {
            const app_status = this.$store.getters["application/getApplication"].status;
            const GLOBAL_EXCLUDED_ROUTES = [
                "error-404",
                "pb-payment-success",
                "pb-payment-error",
                "pb-pay",
                "pb-upload-photos",
                "redirect",
                "faq"
            ];
            const REDIRECTS_MAP = {
                [this.$store.state.application.APPLICATION_STATUSES.PAID]: {
                    destination: "home",
                    exclude: [
                        "debtor-data",
                        "debtor-data-step1",
                        "debtor-data-step2",
                        "debtor-data-step3",
                        "debtor-data-step4",
                        "debtor-data-step5",
                        "debtor-data-step6",
                        "debtor-data-step7",
                        "debtor-data-step8",
                        "debtor-data-step9",
                        "debtor-data-step10"
                    ]
                },
                [this.$store.state.application.APPLICATION_STATUSES.PERSONAL_DATA]: "survey",
                [this.$store.state.application.APPLICATION_STATUSES.SURVEY]: "property-components",
                [this.$store.state.application.APPLICATION_STATUSES.PROPERTY_COMPONENTS]:
                    "recurring-expenses",
                [this.$store.state.application.APPLICATION_STATUSES.RECURRING_EXPENSES]: "debts",
                [this.$store.state.application.APPLICATION_STATUSES.DEBTS]: "documents",
                [this.$store.state.application.APPLICATION_STATUSES.DOCUMENTS]:
                    "application-processing",
                [this.$store.state.application.APPLICATION_STATUSES.VERIFICATION_ERROR]:
                    "application-processing",
                [this.$store.state.application.APPLICATION_STATUSES.VERIFIED]:
                    "application-processing",
                [this.$store.state.application.APPLICATION_STATUSES.GENERATED]: "application-ready",
                [this.$store.state.application.APPLICATION_STATUSES.SUBMITTED]: "application-ready",
                [this.$store.state.application.APPLICATION_STATUSES.SUCCEEDED]: "application-ready",
                [this.$store.state.application.APPLICATION_STATUSES.REJECTED]: "application-ready"
            };
            if (
                REDIRECTS_MAP[app_status] != undefined &&
                this.$route.name !=
                    (typeof REDIRECTS_MAP[app_status] == "object"
                        ? REDIRECTS_MAP[app_status].destination
                        : REDIRECTS_MAP[app_status]) &&
                (GLOBAL_EXCLUDED_ROUTES.indexOf(this.$route.name) === -1 || force === true)
            ) {
                if (
                    typeof REDIRECTS_MAP[app_status] == "object" &&
                    REDIRECTS_MAP[app_status].exclude != undefined &&
                    Array.isArray(REDIRECTS_MAP[app_status].exclude) &&
                    REDIRECTS_MAP[app_status].exclude.indexOf(this.$route.name) !== -1
                ) {
                    return false;
                }

                this.$router.push({
                    name:
                        typeof REDIRECTS_MAP[app_status] == "object"
                            ? REDIRECTS_MAP[app_status].destination
                            : REDIRECTS_MAP[app_status],
                    query: this.$route.query
                });
                return true;
            }
        }
    },

    async created() {
        /*******************************************
         * OBSŁUGA UTRATY I ODZYSKANIA POŁĄCZENIA *
         *******************************************/
        this.testConnection();
        window.addEventListener("online", () => {
            this.online_status = true;
            this.testConnection(true);
        });
        window.addEventListener("offline", () => {
            this.online_status = false;
        });

        /*******************************************************************************
         * AUTOREDIRECT UŻYTKOWNIKA NA RÓŻNE ROUTY W ZALEŻNOŚCI OD STATUSU APPLICATION *
         *******************************************************************************/
        await this.$store.dispatch("application/awaitForApplication");
        this.redirectUserToCurrentApplicationStepIfNeeded();
    },

    metaInfo: {
        title: "Dashboard",
        titleTemplate: "%s | PanelPomocy.pl",
        meta: [
            {
                vmid: "description",
                name: "description",
                content:
                    "Skorzystaj z narzędzia umożliwiającego sprawne i łatwe przygotowanie wniosku o upadłość konsumencką."
            }
        ]
    }
};
