//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PpChatAvatar from "@/components/PpChatAvatar";
import ProgressBar from "@/components/ProgressBar";
import waitForMs from "../helpers/wait-for-ms";
import { calcFileSize } from "../helpers/calc-file-size";

export default {
    components: {
        PpChatAvatar,
        ProgressBar
    },

    props: {
        item: {
            type: Object,
            required: true
        },
        recipient: {
            type: Object
        },
        currentUser: {
            type: String,
            required: true
        },
        isFirstInCreatorGroup: {
            type: Boolean,
            default: false
        },
        isLastInCreatorGroup: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            show_date: false,

            downloading_attachment: false,

            file_thumb_url: null,
            file_thumb_fetched: false
        };
    },

    computed: {
        createdByCurrentUser() {
            return this.item.creator == this.currentUser;
        },
        message_sender() {
            return this.$store.getters["users/getUser"](this.item.creator);
        },
        uploading_file() {
            return this.item.status == "created" && this.item.upload_progress != undefined;
        }
    },

    methods: {
        formatFileSize(fsize) {
            return calcFileSize(fsize);
        },

        async downloadAttachment() {
            if (this.downloading_attachment) return;
            this.downloading_attachment = true;

            await waitForMs(250);
            const FILE_URL = await this.getMessageAttachmentFileUrl();

            try {
                const r = await this.$axios.$get(FILE_URL, {
                    responseType: "blob",
                    transformRequest: (data, headers) => {
                        delete headers.authorization;
                        delete headers["x-authorization"];
                        return data;
                    },
                    headers: {
                        "Cache-Control": "no-cache",
                        "Pragma": "no-cache",
                        "Expires": "0"
                    }
                });

                const FURL = window.URL.createObjectURL(r);
                const A = document.createElement("a");
                A.setAttribute("href", FURL);
                A.setAttribute("download", this.item.attachment.file_name);
                document.body.appendChild(A);
                A.click();
                document.body.removeChild(A);
                window.URL.revokeObjectURL(FURL);
            } catch (err) {
                console.error(err);
            }

            this.downloading_attachment = false;
        },

        async downloadFilePreview() {
            const FURL = await this.getMessageAttachmentFileUrl("thumb");
            if (FURL) {
                this.file_thumb_url = FURL;
            }
            this.file_thumb_fetched = true;
        },

        async getMessageAttachmentFileUrl(file_type = "source_file") {
            try {
                const r = await this.$axios.$get(
                    `/files/${this.item.attachment._id}/signed-url/?file_type=${file_type}`,
                    {
                        supress_errors: true
                    }
                );

                return r.url;
            } catch (err) {
                console.error(err);
            }
        },

        onIntersect() {
            if (
                this.item.attachment != undefined &&
                this.item.attachment._id != "dull" &&
                !this.file_thumb_fetched
            ) {
                this.downloadFilePreview();
            }
        },

        async openAttachmentPreview() {
            const FURL = await this.getMessageAttachmentFileUrl();
            if (FURL) {
                this.$emit("open-file-preview", {
                    file_type: this.item.attachment.file_display_type,
                    url: FURL
                });
            } else {
                this.$message({
                    type: "error",
                    msg: "Nie udało się załadować podglądu pliku"
                });
            }
        }
    },
    created() {
        if (!this.createdByCurrentUser) {
            this.$store.dispatch("users/fetchUser", this.item.creator);
        }
    }
};
