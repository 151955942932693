//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { EventBus } from "@/helpers/EventBus";

export default {
    props: {
        disabled: {
            type: Boolean,
            default: false
        },

        hint: {
            type: String,
            default: ""
        },

        id: {
            type: String,
            default: ""
        },

        label: {
            type: String,
            default: ""
        },

        rules: {
            type: Array,
            default() {
                return [];
            }
        },

        value: {
            type: [String, Number, Boolean],
            default: ""
        },

        name: {
            type: String,
            default: ""
        },

        trueValue: {
            type: [Number, String, Boolean],
            default: true
        },

        falseValue: {
            type: [Number, String, Boolean],
            default: false
        },

        nonFocusable: {
            type: Boolean,
            default: false
        },

        focus: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            local_id: "",
            error_state: false
        };
    },

    computed: {
        hasLabelSlot() {
            return !!this.$slots.label;
        },
        isChecked() {
            return this.value === this.trueValue;
        }
    },

    watch: {
        id() {
            this.handleId();
        }
    },

    methods: {
        toggle() {
            if (this.disabled) return;

            let nv = null;
            if (this.isChecked) return;
            else nv = this.trueValue;

            this.$emit("input", nv);
            this.$emit("change", nv);
            this.$nextTick((_) => {
                this.validate();
                EventBus.$emit("wk-radio-change", {
                    name: this.name,
                    value: nv
                });
            });
        },

        validate() {
            return this.$refs["wk-input"].validate();
        },

        resetValidation() {
            return this.$refs["wk-input"].resetValidation();
        },

        handleId() {
            if (this.id) {
                this.local_id = this.id;
            } else {
                this.local_id =
                    "radio_" +
                    (Date.now() + Math.random() * 100000).toString(16);
            }
        },

        handleCboxGroupChange(payload) {
            if (
                payload.name &&
                payload.name != "" &&
                payload.name == this.name
            ) {
                this.validate();
            }
        }
    },

    created() {
        this.handleId();
        EventBus.$on("wk-radio-change", this.handleCboxGroupChange);
    },

    beforeDestroy() {
        EventBus.$off("wk-radio-change", this.handleCboxGroupChange);
    }
};
