import Vue from "vue";
import Vuex from "vuex";
import application from "./modules/application";
import auth from "./modules/auth";
import chat from "./modules/chat";
import constants from "./modules/constants";
import faq from "./modules/faq";
import formData from "./modules/form-data";
import tpd from "./modules/tpd";
import users from "./modules/users";
import wrappers from "./modules/wrappers";

import countries from "@/static/countries";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        REGEX_EMAIL:
            /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,

        REGEX_PHONE_NUMBER: /^[0-9]{9,15}$/,
        REGEX_PHONE_COUNTRY_CODE: /^\+[0-9\-]{1,7}$/,

        REGEX_ZIP_CODE_PL: /^[0-9]{2}-[0-9]{3}$/,
        REGEX_ZIP_CODE_GENERAL: /^[A-Za-z0-9_-\s\/]{3,20}$/,

        REGEX_TAX_NUMBER_GENERAL: /^[A-Za-z]{2}[0-9A-Za-z\-\/]{3,29}$/,

        REGEX_IS_MONEY:
            /^([^.,0-9]*)?((([1-9][0-9]{0,2})( [0-9]{3})*)|([1-9][0-9]*)|0)((\.|,)[0-9]{1,2})?([^.,0-9]*)?$/,

        REGEX_MONGO_ID: /^[a-f\d]{24}$/i,

        REGEX_PASSWORD_RESET_TOKEN: /^[A-Fa-f0-9]{128}$/,

        REGEX_SHA512: /^[a-fA-F0-9]{128}$/,

        MAX_SAFE_INTEGER: 9007199254740991,

        API_BASE_URL: process.env.VUE_APP_API_BASE_URL,
        API_BASE_PATH: process.env.VUE_APP_API_BASE_PATH,
        SELF_URL: process.env.VUE_APP_SELF_URL,

        USER_ROLE_LABELS: {
            user: "Użytkownik",
            account_manager: "Opiekun Klienta",
            lawyer: "Radca Prawny",
            admin: "Administrator"
        },

        USER_STATUSES_LABELS: {
            preregistered: "Wstępnie utworzone",
            unconfirmed: "Niezweryfikowane",
            active: "Aktywne",
            blocked: "Zablokowane",
            suspended: "Zawieszone"
        },

        storage: {},
        messages: [],

        colors: {
            primary: "#1D2D4A",
            primary_light: "#EDEEF1",
            secondary: "#BF934F",
            secondary_light: "#EFE4D3",
            error: "EC2F23",
            success: "#00A770"
        },

        app_booted: false,
        initial_layout_set: false,

        force_hide_hint: false,
        survey_active_question_id: null
    },

    getters: {
        PASSWORD_VALIDATOR: () => password => {
            let valid = true;
            if (password.length < 8) valid = false;
            else if (password.length > 63) valid = false;
            else if (!/[a-ząćęłńóśżźàâæçéèêëîïôœùûüÿöäß]+/.test(password)) valid = false;
            else if (!/[A-ZĄĆĘŁŃÓŚŻŹÀÂÆÇÉÈÊËÎÏÔŒÙÛÜŸÖÄẞ]+/.test(password)) valid = false;
            else if (!/[0-9]+/.test(password)) valid = false;
            return valid;
        },

        VALIDATOR_TAX_NUMBER_PL: () => value => {
            if (/^[0-9]{10}$/.test(value) && value != "0000000000") {
                // NIP PL
                const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
                const NIP = value.split("");
                let SUM = 0;
                for (let nnn = 0; nnn < 9; nnn++) SUM += parseInt(NIP[nnn]) * weights[nnn];
                if (SUM % 11 != NIP[9]) return false;

                return true;
            } else {
                // invalid NIP
                return false;
            }
        },
        VALIDATOR_TAX_NUMBER_GENERAL: state => value => {
            if (state.REGEX_TAX_NUMBER_GENERAL.test(value)) {
                const NIP = value.split("");
                const letters = NIP[0] + NIP[1];

                //kod alpha2 musi być prawidłowy
                if (
                    !/^A[^ABCHJKNPVY]|B[^CKPUX]|C[^BEJPQST]|D[EJKMOZ]|E[CEGHRST]|F[IJKMOR]|G[^CJKOVXZ]|H[KMNRTU]|I[DEL-OQ-T]|J[EMOP]|K[EGHIMNPRWYZ]|L[ABCIKR-VY]|M[^BIJ]|N[ACEFGILOPRUZ]|OM|P[AE-HK-NRSTWY]|QA|R[EOSUW]|S[^FPQUW]|T[^ABEIPQSUXY]|U[AGMSYZ]|V[ACEGINU]|WF|WS|YE|YT|Z[AMW]$/i.test(
                        letters
                    ) ||
                    letters.toUpperCase() == "PL"
                ) {
                    return false;
                }

                return true;
            } else {
                // invalid NIP
                return false;
            }
        },

        VALIDATOR_PERSONAL_ID: () => value => {
            if (value.length !== 11) {
                return false;
            }
            const numWeight = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
            const yy = value.slice(0, 2);
            const mm = value.slice(2, 4);
            const dd = value.slice(4, 6);
            const controlNumber = value
                .split("")
                .slice(0, -1)
                .map((n, i) => n * numWeight[i])
                .reduce((acc, current) => acc + current, 0)
                .toString()
                .slice(-1);

            if (10 - (controlNumber == 0 ? 10 : controlNumber) != value[value.length - 1]) {
                return false;
            }

            if (
                isNaN(
                    new Date(
                        `${mm % 20}/${dd}/${
                            19 + Math.floor(mm.toString()[0] / 2 === 4 ? -1 : mm.toString()[0] / 2)
                        }${yy}`
                    ).getMonth()
                )
            ) {
                return false;
            }

            return true;
        },

        getBirthDateFromPESEL: (state, getters) => pesel => {
            if (!getters.VALIDATOR_PERSONAL_ID(pesel)) return "";
            let yp = pesel.slice(0, 2);
            let mp = pesel.slice(2, 4);
            let dp = pesel.slice(4, 6);

            const mpfc = mp.slice(0, 1);
            if (mpfc == "0" || mpfc == "1") {
                // 1900-1999 - nie robimy nic
                yp = "19" + yp;
            } else if (mpfc == "2" || mpfc == "3") {
                // 2000-2099
                mp = (mpfc - 2).toString() + mp.slice(1, 2);
                yp = "20" + yp;
            } else if (mpfc == "4" || mpfc == "5") {
                // 2100-2199
                mp = (mpfc - 4).toString() + mp.slice(1, 2);
                yp = "21" + yp;
            } else if (mpfc == "6" || mpfc == "7") {
                // 2200-2299
                mp = (mpfc - 6).toString() + mp.slice(1, 2);
                yp = "22" + yp;
            } else if (mpfc == "8" || mpfc == "9") {
                // 2200-2299
                mp = (mpfc - 8).toString() + mp.slice(1, 2);
                yp = "18" + yp;
            }

            return `${yp}-${mp}-${dp}`;
        },

        getTaxNumberLocale: (state, getters) => tax_no => {
            if (getters.VALIDATOR_NIP_PL(tax_no)) return "PL";
            else if (state.REGEX_TAX_NUMBER_GENERAL.test(tax_no)) {
                return tax_no.slice(0, 2).toUpperCase();
            }
            return false;
        },

        pluralize: state => obj => {
            if (obj.n === undefined || isNaN(obj.n)) return "INVALID_N_PROPERTY";

            function getChoiceIndex(choice) {
                if (choice === 0) return 0;
                if (choice === 1) return 1;

                const teen = choice > 10 && choice < 20;
                const endsWithTwoThreeOrFour = choice % 10 >= 2 && choice % 10 <= 4;

                if (teen || (!teen && !endsWithTwoThreeOrFour)) return 3;
                return 2;
            }
            const x = getChoiceIndex(obj.n);

            if (obj.values && Array.isArray(obj.values)) {
                if (x < obj.values.length) return obj.values[x].replace("{{n}}", obj.n);
                else return "INVALID_VALUES_LENGTH";
            } else if (obj.message && typeof obj.message == "string") {
                if (
                    state?.pluralization_messages[obj.message] &&
                    Array.isArray(state.pluralization_messages[obj.message]) &&
                    state.pluralization_messages[obj.message].length > x
                )
                    return state.pluralization_messages[obj.message][x].replace("{{n}}", obj.n);
                else return "INVALID_MESSAGES_LENGTH";
            } else return "NO_TRANSLATION_DATA";
        },

        getStorageItem: state => key => {
            if (state.storage[key] !== undefined) return state.storage[key];
            return null;
        },

        getColor: state => color => {
            if (state.colors[color] != undefined) {
                return state.colors[color];
            }
            return null;
        },

        displayGeneralAddress: () => address => {
            if (
                !address ||
                address.street == "" ||
                address.house_no == "" ||
                address.zip_code == "" ||
                address.city == ""
            ) {
                return "-";
            }
            return `${address.street} ${address.house_no}${
                address.apartment_no ? "/" + address.apartment_no : ""
            }, ${address.zip_code} ${address.city}, ${
                countries.find(item => item.alpha2 == address.country)?.name?.pl || "-"
            }`;
        },

        forceHideHint: state => {
            return state.force_hide_hint;
        }
    },

    mutations: {
        setStorageItem(state, payload) {
            state.storage[payload.key] = payload.value;
        },
        removeStorageItem(state, key) {
            if (state.storage[key] !== undefined) {
                delete state.storage[key];
            }
        },

        addMessage(state, data) {
            state.messages.push({
                type: data.type,
                msg: data.msg,
                use_html: data.use_html || false,
                title: data.title || null,
                id: data.id
            });
        },
        removeMessage(state, id) {
            let index = state.messages.findIndex(i => i.id == id);
            if (index != -1) state.messages.splice(index, 1);
        },

        setForceHideHint(state, status) {
            state.force_hide_hint = status;
        }
    },

    actions: {
        addMessage({ commit }, data) {
            const id = "message_" + Date.now() + "_" + Math.floor(Math.random() * 10000);

            commit("addMessage", {
                ...data,
                id
            });

            if (!data.duration || data.duration != -1) {
                let dur = 3500;
                if (data.duration) dur = data.duration;

                setTimeout(() => {
                    commit("removeMessage", id);
                }, dur);
            }
        }
    },

    modules: {
        application,
        auth,
        chat,
        constants,
        faq,
        formData,
        tpd,
        users,
        wrappers
    }
});
