//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PpChatWindow from "@/components/PpChatWindow";
import PfozTimer from "@/components/PfozTimer";
import AppTimeline from "@/components/AppTimeline";
import AppTimelineItem from "@/components/AppTimelineItem";

export default {
    components: {
        PpChatWindow,
        PfozTimer,
        AppTimeline,
        AppTimelineItem
    },

    data() {
        return {
            show_chat: false,
            show_hint: false,
            show_mobile_menu: false,

            lawyer_fetched: false,
            lawyer: null,

            hintable_routes: ["survey", "property-components", "recurring-expenses"]
        };
    },

    computed: {
        user() {
            return this.$store.state.auth.user;
        },
        app() {
            return this.$store.getters["application/getApplication"];
        },
        app_status() {
            const app = this.$store.getters["application/getApplication"];
            return app ? app.status : "none";
        },
        route_is_hintable() {
            if (this.$route.name == "survey" && this.$store.getters["forceHideHint"]) return false;
            return this.hintable_routes.indexOf(this.$route.name) !== -1;
        },

        lawyer_formatted_phone_number() {
            if (!this.lawyer) return "";
            const P = this.lawyer.phone.replace(/\s/g, "");
            if (P.length === 9) {
                return P.slice(0, 3) + " " + P.slice(3, 6) + " " + P.slice(6);
            } else if (P.length === 12 && P[0] === "+") {
                return P.slice(0, 3) + " " + P.slice(3, 6) + " " + P.slice(6, 9) + " " + P.slice(9);
            }
            return this.lawyer.phone;
        },

        chat_unread_messages_count() {
            return this.$store.state.chat.unread_count;
        }
    },

    methods: {
        goToFaq(setAutofocus = false) {
            if (this.$route.name === "faq") return;

            if (setAutofocus && window.localStorage) {
                localStorage.setItem("faq-set-autofocus", true);
            }
            this.$router.push("/faq");
        },

        onLogoClick() {
            if (this.$route.name !== "home") {
                this.$router.push("/");
            }
        },

        callLawyer() {
            if (!this.lawyer) return;

            const L = document.createElement("a");
            L.setAttribute("href", `tel:${this.lawyer.phone.replace(/\s/g, "")}`);

            document.body.appendChild(L);
            L.click();

            document.body.removeChild(L);
        }
    },

    async mounted() {
        if (!this.$store.state.initial_layout_set) return;
        await this.$store.dispatch("application/awaitForApplication");

        try {
            // pobranie ilości nieprzeczytanych wiadomości na czacie
            const r = await this.$axios.$get(
                `/chat-messages/unread-count/?application=${this.$store.getters["application/getApplication"]._id}`
            );
            this.$store.state.chat.unread_count = r.unread_count;

            // jeżeli w query jest open_chat=1, to go na start otwieramy
            if (this.$route.query.open_chat == "1") {
                this.show_chat = true;
            }

            // pobiranie danych prawnika (potrzebne do numeru telefonu na ten moment)

            this.lawyer = await this.$store.dispatch(
                "users/fetchUser",
                this.$store.getters["application/getApplication"].lawyer
            );
            this.lawyer_fetched = true;
        } catch (err) {
            console.error(err);
        }
    },

    metaInfo() {
        return {
            bodyAttrs: {
                class: this.show_chat ? "md-cns" : ""
            }
        };
    }
};
