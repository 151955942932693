//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { createPopper } from "@popperjs/core";
import { EventBus } from "@/helpers/EventBus";

export default {
    props: {
        placement: {
            type: String,
            default: "right"
        },
        solo: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            local_id: null,
            tooltip_visible: false,
            popper_instance: null,

            positions: ["top", "left", "right", "bottom"]
        };
    },

    methods: {
        getOtherPositions(exclusion) {
            const a = [].concat(this.positions);
            const ix = a.indexOf(exclusion);
            if (ix !== -1) a.splice(ix, 1);
            return a;
        },

        showTooltip() {
            this.popper_instance = createPopper(
                this.$refs["reference"],
                this.$refs["popper"],
                {
                    placement: this.placement,
                    modifiers: [
                        {
                            name: "flip",
                            options: {
                                fallbackPlacements: this.getOtherPositions(
                                    this.placement
                                )
                            }
                        },
                        {
                            name: "offset",
                            options: {
                                offset: [0, 8]
                            }
                        },
                        {
                            name: "arrow",
                            options: {
                                element: this.$refs.arrow,
                                padding: 5
                            }
                        }
                    ]
                }
            );

            this.$nextTick((_) => {
                this.tooltip_visible = true;
                EventBus.$emit("wk-tooltip:open", this.local_id);
            });
        },

        hideTooltip() {
            this.tooltip_visible = false;
            this.destroyPopper();
        },

        destroyPopper() {
            if (this.popper_instance != null) this.popper_instance.destroy();
            this.popper_instance = null;
        },

        onOtherTooltipOpen(id) {
            // console.log('asd')
            if (id != this.local_id && this.solo) {
                this.hideTooltip();
            }
        }
    },

    created() {
        this.local_id =
            "ttip_" + (Date.now() + Math.random() * 100000).toString(16);
    },

    mounted() {
        EventBus.$on("wk-tooltip:open", this.onOtherTooltipOpen);
    },

    beforeDestroy() {
        EventBus.$off("wk-tooltip:open", this.onOtherTooltipOpen);
        this.hideTooltip();
    }
};
