var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-timeline-item",class:{
        'app-timeline-item--completed': _vm.completed,
        'app-timeline-item--active': _vm.active,
        'app-timeline-item--dashed': _vm.dashedLine
    }},[_c('div',{staticClass:"app-timeline-item__icon",class:{
            'app-timeline-item__icon--completed': _vm.completed,
            'app-timeline-item__icon--active': _vm.active
        }}),_c('div',{staticClass:"app-timeline-item__content",class:{
            'text-weight-bold': _vm.active,
            'text-muted': !_vm.completed && !_vm.active
        }},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }